
export default {
	name: 'VisitorQuestionCreatePage',
	props: {
		editData: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			// 表单数据
			submitData: {
				description: '', // 题目
				type: '', // 可选项类型
				options: [], // 可选项
				inputtype: '', // 填空题的类型: 文本/数字/日期时间（TXT/NUM/DATE）
			},
		}
	},
	computed: {
		// 问题表单校验
		submitRules() {
			const optionsValidator = (rule, value, cb) => {
				if (value.length) {
					// 有一项填了就行
					if (value.some(vItem => vItem.description)) {
						// 是否小于200字
						let isAllLess200 = value.map(vItem => vItem.description).every(dItem => dItem.length <= 200)
						if (isAllLess200) {
							cb()
						} else {
							cb(new Error(this.$t('visitorQuestion.visitorQuestionOption200Tips')))
						}
					} else {
						cb(new Error(this.$t('visitorQuestion.visitorQuestionOptionalInputTips')))
					}
				} else {
					cb(new Error(this.$t('visitorQuestion.visitorQuestionOptionalTips')))
				}
			}
			return {
				description: [
					{ required: true, message: this.$t('visitorQuestion.visitorQuestionTopicTips'), trigger: 'blur' },
					{ max: 200, message: this.$t('visitorQuestionTmpl.visitorQuestion200Tips'), trigger: 'blur' }
				],
				type: [{required: true, message: this.$t('visitorQuestion.visitorQuestionTopicTypeTips'), trigger: 'change'}],
				options: [{ required: true, validator: optionsValidator, trigger: 'change' }],
				inputtype: [{ required: this.submitData && this.submitData.type === 'IN', message: this.$t('visitorQuestion.visitorQuestionInputTypeTips'), trigger: 'change' }]
			}
		},
		// 问题类型列表
		issueTypeList() {
			return [
				{
					value: 'SC',
					label: this.$t('visitorQuestion.visitorQuestionTypeSc'),
				},
				{
					value: 'MC',
					label: this.$t('visitorQuestion.visitorQuestionTypeMc'),
				},
				{
					value: 'IN',
					label: this.$t('visitorQuestion.visitorQuestionTypeIn'),
				}
			]
		},
		// 填空题的类型列表
		inputTypeList() {
			return [
				{
					value: 'TXT',
					label: this.$t('visitorQuestion.visitorQuestionInputtypeTxt'),
				}, {
					value: 'NUM',
					label: this.$t('visitorQuestion.visitorQuestionInputtypeNum'),
				}, {
					value: 'DATE',
					label: this.$t('visitorQuestion.visitorQuestionInputtypeDate'),
				}
			]
		},
		// 禁用添加一项
		disabledAddOption() {
			return this.submitData.options.length >= 9
		}
	},
	created() {
		if (this.editData) {
			let submitData = JSON.parse(JSON.stringify(this.editData))
			this.submitData = {
				...submitData,
				options: JSON.parse(JSON.stringify(submitData.body))
			}
		}

	},
	methods: {
		// 获取类型描述
		getTypeDesc(row) {
			let currentType = this.issueTypeList.find(iitem => iitem.value === row.question.type)
			return currentType.label
		},
		// 问题类型变换
		questionTypeChange() {
			this.$nextTick(() => {
				if (Array.isArray(this.$refs.createForm)) {
					this.$refs.createForm[0].clearValidate(['options', 'inputtype'])
				} else {
					this.$refs.createForm.clearValidate(['options', 'inputtype'])
				}
			})
		},
		// 加一项 是否符合规则
		optionIsConform(optionItem) {
			return optionItem.description && optionItem.description.length <= 200
		},
		// 删除问题可选项
		issueOptionDelete(item) {
			this.submitData.options.splice(this.submitData.options.findIndex(iItem => iItem.seq === item.seq), 1)
		},
		// 添加一项问题可选项
		addIssueOption() {
			let optionCount = this.submitData.options.length
			if (optionCount) {
				if (optionCount >= 9) return
				let max = Math.max(...this.submitData.options.map(iItem => iItem.seq))
				this.submitData.options.push({ seq: max + 1, description: '' })
			} else {
				this.submitData.options.push({ seq: 1, description: '' })
			}
		},
		// 提交
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {

					// 过滤掉没值的可选项
					let optionList = this.submitData.options.filter(oItem => oItem.description)
					if (this.submitData.type === 'IN') {
						optionList = []
					} else if (this.submitData.type === 'SC' || this.submitData.type === 'MC') {
						this.submitData.inputtype = ''
					}
					if (this.editData) {
						// 修改数据
						const requsetEditJson = {
							hrid: this.$store.getters.loginid,
							qnum: this.submitData.qnum,
							// name: this.submitData.name,
							description: this.submitData.description,
							inputtype: this.submitData.inputtype,
							type: this.submitData.type,
							body: optionList

						}
						this.$http.post('/v2/question/edit', requsetEditJson).then(response => {
							if (response.data.success) {
								this.$message({
									type: 'success',
									message: this.$t('visitorQuestion.editSuccess')
								});
								this.$emit('createSuccess',true)
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})
					} else {
						const requsetJson = {
							hrid: this.$store.getters.loginid,
							name: this.submitData.name,
							description: this.submitData.description,
							inputtype: this.submitData.inputtype,
							type: this.submitData.type,
							body: optionList
						}
						this.$http.post('/v2/question/add', requsetJson).then(response => {
							if (response.data.success) {

								this.$message({
									type: 'success',
									message: this.$t('visitorQuestion.createSuccess')
								});
								this.$emit('createSuccess')
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})
					}

				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
		// 关闭
		closeDrawer() {
			this.$emit('closeDialog')
		},
	}
}