
export default {
  name: 'NoticePublishRangePage',
  props: {
    editData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // 用户列表数据
      accountlist: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      // 搜索条件
      query: '',
      // 选择项
      selectedData: [],
      // 选择表格高度
      selectTableHeight: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.selectedData = JSON.parse(JSON.stringify(this.editData))
      this.selectTableHeight = this.$refs.tableWrapper.offsetHeight - 65
      this.getAccountData()
    })

  },
  methods: {
    //用户列表
    getAccountData(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http.post('/account/accountlist', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
        "query": this.query,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
      }, {
        loadTarget: this.$refs.tableWrapper
      }).then(response => {
        // 更新数据
        if (response.data.success) {
          this.accountlist = response.data.content;
          this.pagesObj = {
            ...this.pagesObj,
            // page: response.data.page,
            total: response.data.total
          }

          this.$nextTick(() => {
            this.selectedData.forEach(element => {
              let selectRow = this.accountlist.find((item) => item.hrid == element.hrid)
              if (selectRow) {
                this.$refs.tableRef.toggleRowSelection(selectRow, true)
              }

            })
          })

        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 列表选择项
    selectionChange(data, row) {
      let isCancelSelect = false
      // 取消选项
      if (data.length == 0) {
        isCancelSelect = true

      } else {
        if (data[data.length - 1].hrid != row.hrid) {

          isCancelSelect = true
        }
      }

      if (isCancelSelect) {
        for (let index = 0; index < this.selectedData.length; index++) {
          if (this.selectedData[index].hrid == row.hrid) {
            this.selectedData.splice(index, 1)
          }

        }
      } else {
        this.selectedData.push(data[data.length - 1])
      }
      // this.selectedData = data
    },
    // 全选
    selectAll(data) {
      if (data.length > 0) {
        // let selectedData = []
        data.forEach(item => {
          let isAdd = this.selectedData.find((element) => element.hrid == item.hrid)
          if (!isAdd) {
            this.selectedData.push(item)
          }
        })
      } else {
        this.accountlist.forEach(item => {
          for (let index = 0; index < this.selectedData.length; index++) {
            if (this.selectedData[index].hrid == item.hrid) {
              this.selectedData.splice(index, 1)
              break
            }
          }
        })
      }
    },
    // 搜索
    searchEvent() {
      this.getAccountData()
    },
    // 确认选择
    confirmSelect() {
      this.$emit('confirmSelect', this.selectedData)
    },
    // 关闭
    closeSelectRange() {
      this.$emit('closeSelectRange')
    },
    // 删除已选
    deleteClick(row) {
      this.$utils.blurFn()
      this.selectedData = this.selectedData.filter(item => item.hrid !== row.hrid)
      let currentRow = this.accountlist.find(item => item.hrid === row.hrid)
      currentRow && this.$refs.tableRef.toggleRowSelection(currentRow, false)
    },
    // 删除全部已选
    clearAll() {
      this.$refs.tableRef.clearSelection()
      this.selectedData = []
    }
  }
}