<template>
  <el-container class="container">
    <el-main class="main-wrapper">
      <div class="main-header">
        <el-form label-width="auto">
          <!-- 标题 -->
          <el-form-item :label="$t('notice.noticeTitle') + ':'">
            {{ viewData.title }}
          </el-form-item>
          <!-- 概要 -->
          <el-form-item :label="$t('notice.noticeOutline') + ':'">
            {{ viewData.outline }}
          </el-form-item>
          <!-- 发布人 -->
          <el-form-item :label="$t('notice.publishName') + ':'">
            {{ viewData.createbyname }}
          </el-form-item>
          <!-- 发布时间 -->
          <el-form-item :label="$t('notice.publishTime') + ':'">
            {{ viewData.timing == null || viewData.timing == '' ? viewData.createdate : viewData.timing }}
          </el-form-item>
        </el-form>
      </div>
      <div ref="tableWrapper" class="table-wrapper">
        <el-table ref="tableRef" :data="getreadinglist" style="width: 100%">
          <el-table-column prop="hrname" :label="$t('noticeRead.notifyperName')"> </el-table-column>
          <el-table-column prop="reading" :label="$t('noticeRead.isRead')">
            <template slot-scope="scope">
              <div class="yes" v-if="scope.row.reading == '1'">{{ $t('common.yes') }}</div>
              <div class="no" v-else>{{ $t('common.no') }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="readtime" :label="$t('noticeRead.noticeReadTime')"> </el-table-column>
          <div slot="empty">
            <el-empty :description="$t('notice.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
          </div>
        </el-table>
        <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getReadingData"></pagination-box>
      </div>
    </el-main>
    <el-footer class="footer-wrapper" height="70px">
      <el-button @click="closeDialog">{{ $t('common.close') }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
