export default {
	name: 'UserinfoEditPassPage',
	props: {
		editData: {
			type: Object,
			required: true
		}
	},
	data() {
		const checkPassword = (rule, value, callback) => {
			if (value == this.submitData.newpassword) {
				return callback();
			} else {
				return callback(new Error(this.$t('userinfoEditPhone.repassTips')));
			}
		}
		return {
			// 表单数据
			submitData: {
				newpassword: '',
				checkpassword: '',
			},
			// 校验规则
			submitRules: {
				newpassword: [
					{ required: true, pattern: /^[a-zA-Z0-9@#&*_]{6,20}$/, message: this.$t('userinfoEditPhone.configNewPassFormat'), trigger: 'blur' },
				],
				checkpassword: [
					{ validator: checkPassword, trigger: 'blur' }
				],
			},
		}
	},
	mounted() {
		console.log('this.e', this.editData);

	},
	methods: {
		// 提交
		submitDataFun(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$http
						.post('/home/edituserinfo', {
							hrid: this.$store.getters.loginid,
							phone:this.editData.phone,
							displayname:this.editData.displayname,
							selfregister: this.$store.getters.selfregister ? '1' : '0',
							password: this.submitData.newpassword,
							roleaccount: this.editData.roleaccount,

							// hrid: this.$store.getters.loginid,
							// selfregister: this.$store.getters.selfregister ? '1' : '0',
							// phone: this.editData.phone,
							// displayname: this.editData.displayname,
							// password: this.submitData.newpassword,
							// isdisplaypedemo: this.editData.isdisplaypedemo,
							// weblogo: this.editData.fileUserImgBase64,
							// qrcodelogo: this.editData.fileUserImgBase64Two,
							// maptype: this.$store.getters.maptype,
							// allowcrosspe: this.editData.allowtask,
							// switch: this.editData.isOpenSMS,
							// email: this.editData.email
						})
						.then((response) => {
							if (response.data.success) {
								this.$message({
									type: 'success',
									message: this.$t('userinfoEditPass.editPassSuccess')
								});
								this.closeDialog()

							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})

				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
		// 关闭
		closeDialog() {
			this.$emit('closeEditPass')
		}
	}
}