export default {
  name: 'ForgotPage',
  data() {
    return {
      // 语言选项
      languageItemArr: [
        {
          label: 'English',
          value: "en"
        },
        {
          label: '简体中文',
          value: "zh"
        },
        {
          label: '繁體中文(港澳)',
          value: "zht"
        },
        {
          label: '繁體中文(台灣)',
          value: "tw"
        }
      ],
      countryList: [
        {
          id: '86',
          name: 'CN +86',
        },
        {
          id: '852',
          name: 'HK +852',
        },
        {
          id: '853',
          name: 'MO +853',
        },
        {
          id: '886',
          name: 'TW +886',
        },
        {
          id: '60',
          name: 'MAS +60',
        },
        {
          id: '65',
          name: 'SG +65',
        },
        {
          id: '61',
          name: 'AU +61',
        },
      ],
      stepNum: 1,
      countryVal: '',
      // 自定义区号
      inputAreacodeList: [],
      inputAreacodeShow: false,
      imgsrc: '',
      imgcodekey: '',
      wait: 60,
      // 表单数据
      oneStepSubmitData: {
        areacode: '',
        primaryphone: '',
        img_code: '',
        code: ''
      },
      iscode: true,
      time: 5,
      twoStepSubmitData: {
        password: '',
        passwordcheck: ''
      },
      hrid: ''
    }
  },
  created() {
    this.setAreacode()
    // this.oneStepSubmitData.areacode = this.countryList[0].id;
    this.getImageCode()
  },
  computed: {
    // 教程视频
    tutorialVideos() {
      let links = {
        zh: ['https://www.313fm.com/course/313fm-1.mp4', 'https://www.313fm.com/course/313fm-2.mp4', 'https://www.313fm.com/course/313fm-3.mp4', 'https://www.313fm.com/course/313fm-4.mp4', 'https://www.313fm.com/course/313fm-5.mp4', 'https://www.313fm.com/course/313fm-6-part1.mp4', 'https://www.313fm.com/course/313fm-6-part2.mp4'],
        zht: ['https://youtu.be/cDFRAKzx43c', 'https://youtu.be/H93zMhR5_uk', 'https://youtu.be/Q8OodFJ3Zwo', 'https://youtu.be/p2RW70rUJB0', 'https://youtu.be/7hnqckHJodU', 'https://youtu.be/fRs_J5cpcqU', 'https://youtu.be/D6IN9LUk7r0'],
        en: ['https://youtu.be/e5NeVNoXO4Y', 'https://youtu.be/S2Q9Z4bLZ4Q', 'https://youtu.be/LT4XzXOrYJw', 'https://youtu.be/p0WIJOY3KYw', 'https://youtu.be/RE42Hi0o2kQ', 'https://youtu.be/KGnHGfdOhV8', 'https://youtu.be/_TJaGb83CyE'],
      }
      let lang = this.$store.getters.lang === 'tw' ? 'zht' : this.$store.getters.lang || 'zht';
      return links[lang].map((link, index) => ({
            label: this.$t(`tutorialVideo.${index < 5 ? 'basic_video' + (index + 1) : 'other_video' + (index - 4)}`),
            link,
          })
      );
    },
    // 校验规则
    oneStepSubmitRules() {
      const validatePhone = (rule, value, callback) => {
        let phonevalid = true;
        if (phonevalid) {
          this.$http.post('/common/checkphone', {
            areacode: this.oneStepSubmitData.areacode,
            phone: value,
            type: "2",
            langcode: "ZH"
          }, { disabledAuth: true, showLoading: false }).then((response) => {
            if (response.data.success) {
              if (this.wait === 60) {
                this.iscode = false;
              }
              if (response.data.isPhoneInAnotherHost) {
                this.$alert(this.$t('login.webSiteVersion', { anotherSite: this.$utils.getAnotherSite() }), this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true,
                  callback: () => {
                    window.location.href = this.$utils.getAnotherSite() + "/#/forgot";
                  }
                })
              }
              callback()
            } else {
              this.iscode = true;
              return callback(new Error(this.$t('forgot.phoneIsNotExist')))

            }
          })
        }
      }
      return {
        primaryphone: [
          { required: true, message: this.$t('login.loginPhoneTips'), trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' },
        ],
        img_code: [
          { required: true, message: this.$t('forgot.correctImageCode'), trigger: 'blur' },
        ],
        code: [
          { required: true, pattern: /^\d{6}\b/, message: this.$t('forgot.correctCode'), trigger: 'blur' },
        ]
      }
    },
    // 校验规则
    twoStepSubmitRules() {
      const validateRepPassword = (rule, value, callback) => {
        if (value == '') {
          callback(new Error(this.$t('forgot.passIsConsistent')))
        }
        if (this.twoStepSubmitData.password == value && value != '') {
          callback()
        } else {
          callback(new Error(this.$t('forgot.passIsConsistent')))
        }
      }
      return {
        password: [
          { required: true, pattern: /^[a-zA-Z0-9@#&*_]{6,20}$/, message: this.$t('forgot.configNewPassformat'), trigger: 'blur' },

        ],
        passwordcheck: [
          { required: true, validator: validateRepPassword, trigger: 'blur' },
        ]
      }
    },
    showLanguage() {
      const currentLanguage = this.languageItemArr.find(item => item.value === this.$store.getters.lang)
      return currentLanguage.label
    },
  },
  methods: {
    getOfficialWebsite() {
      if (this.$store.getters.lang == 'zht') {
        window.open('https://www.313fm.com/tc/');
      } else if (this.$store.getters.lang == 'en') {
        window.open('https://www.313fm.com/en/');
      } else {
        window.open('https://www.313fm.com');
      }
    },
    // 只输入数字
    handleNumInput(value) {
      /* eslint-disable */
      const reg = /^[\d\.]+$/;
      if (value && !reg.test(value)) {
        this.oneStepSubmitData.primaryphone = value.replace(/[^\d\.]/g, '');
      }
    },
    // 获取IP信息
    getIPInfo() {
      let abortController = new AbortController()
      return new Promise((resolve, reject) => {
        clearTimeout(this.abortGetIPTimer)
        // 3秒后终止请求
        this.abortGetIPTimer = setTimeout(() => {
          abortController.abort()
        }, 3000)
        fetch('https://ipapi.co/json/', {signal: abortController.signal}).then(res => {
          res.json().then(data => {
            resolve(data)
          })
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // 设置默认区号
    setAreacode() {
      this.areaCodeLoading = true
      // 设置区号
      const setData = (acode) => {
        if (acode) {
          let areacode = this.countryList.find(e => e.id == acode)
          if (areacode) {
            this.oneStepSubmitData.areacode = areacode.id
          } else {
            this.oneStepSubmitData.areacode = acode
          }
        } else {
          this.oneStepSubmitData.areacode = this.countryList[0].id;
        }
        this.areaCodeLoading = false
        // 如果区号列表和自定输入区号的列表中都没有当前的区号就新增
        if (!this.countryList.some(cItem => cItem.id === this.oneStepSubmitData.areacode) && !this.inputAreacodeList.some(iItem => iItem.id === this.oneStepSubmitData.areacode)) {
          this.handlecountryInput(this.oneStepSubmitData.areacode)
        }
      }
      // 有缓存拿缓存，没有才请求ip
      if (this.$store.getters.areaCode) {
        setData(this.$store.getters.areaCode)
      } else {
        this.getIPInfo().then(res => {
          setData(res.country_calling_code ? res.country_calling_code.replace('+', '') : '')
        }).catch(err => {
          console.log('err', err);
          setData()
        })
      }
    },
    // 选择区号
    handelChangeAreacode(val) {

      if (val === 'more') {
        this.inputAreacodeShow = true;
        this.$nextTick(() => {
          this.$refs.countryInput.focus();
        });
      }
    },
    // 输入自定义区号
    handlecountryInput(val) {
      if (val === '') return;
      this.inputAreacodeList.push({
        id: val,
        name: val,
      });
      this.oneStepSubmitData.areacode = val;
      // this.checkPhone();
      this.inputAreacodeShow = false;
      this.countryVal = ''
      this.oneStepSubmitData.inputAreacode = '';
    },
    // 获取验证码
    getCode() {
      // var secgetcode = this.getTranslate('secgetcode');
      let timer = setInterval(() => {
        // _this.istimer = true;
        this.wait = this.wait - 1;
        // _this.codeshow = _this.wait + secgetcode;
        this.iscode = true;
        if (this.wait === 0) {
          clearInterval(timer);
          // _this.istimer = false;
          // _this.codeshow = _this.getTranslate('getcode');
          this.wait = 60;
          this.iscode = false;
        }
      }, 1000);

      if (this.wait === 60) {
        this.$http.post('/common/gencode', {
          "areacode": this.oneStepSubmitData.areacode,
          "phone": this.oneStepSubmitData.primaryphone,
          "type": '2',
          "langcode": this.$store.getters.lang,
          code: this.oneStepSubmitData.img_code,
          key: this.imgcodekey,
        }, { disabledAuth: true }).then((response) => {
          if (!response.data.success) {
            if (this.$t(`common.${response.data.message}`).indexOf('common.') == -1) {
              if (response.data.message === 'ERR_NOTICE_0001') {

                this.$message({
                  type: 'error',
                  message: '+' + this.oneStepSubmitData.areacode + this.oneStepSubmitData.primaryphone + this.$t(`common.${response.data.message}`)
                });

              } else {
                this.$message({
                  type: 'error',
                  message: this.$t(`common.${response.data.message}`)
                });
              }
            } else {
              this.$message({
                type: 'error',
                message: response.data.message
              });
              this.getImageCode()
            }
            clearInterval(timer);
            // _this.istimer = false;
            // _this.codeshow = _this.getTranslate('getcode');
            this.wait = 60;
            this.iscode = false;
          } else {

            this.$message({
              type: 'success',
              message: this.$t('forgot.sendCodeSuccess')
            });
          }
        })
      }
    },
    getImageCode() {
      this.$http.post('/common/genimgcode', {}, { disabledAuth: true }).then((response) => {
        if (response.data.success) {
          this.imgsrc = response.data.content.imagesrc;
          this.imgcodekey = response.data.content.key;
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 切换语言
    switchLanguage(lang) {
      this.$store.commit('SET_LANG', lang)
      this.$i18n.locale = this.$store.getters.lang
      this.$pubsub.publish('langSwitchEvent');
    },
    //第一步
    pwdFirst() {
      this.$refs['oneStepForm'].validate((valid) => {
        if (valid) {
          this.$http.post('/common/checkverifycode', {
            "phone": this.oneStepSubmitData.primaryphone,
            "code": this.oneStepSubmitData.code,
            "langcode": this.$store.getters.lang
          }, { disabledAuth: true }).then((response) => {
            if (response.data.success) {
              this.stepNum = 2
              this.hrid = response.data.content.hrid;
              // _this2.phonePage = true;
              // _this2.passwdPage = false;
            } else {
              this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true
              })
            }
          });

        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    //第二步
    pwdget() {
      this.$refs['twoStepForm'].validate((valid) => {
        if (valid) {
          this.$http.post('/common/changepwd', {

            "password": this.twoStepSubmitData.password,
            "hrid": this.hrid,
            "ownerphone": this.oneStepSubmitData.primaryphone,
            "code": this.oneStepSubmitData.code,
            "langcode": this.$store.getters.lang
          }, { disabledAuth: true }).then((response) => {
            if (response.data.success) {
              // this.hrid = response.data.content.hrid;
              // _this2.phonePage = true;
              // _this2.passwdPage = false;
              this.stepNum = 3
              //第三步
              // let timer = setInterval(() => {
              //   this.time = this.time - 1;
              //   if (this.time === 0) {
              //     clearInterval(timer);
              //     this.$router.push("/login")
              //   }
              // }, 1000);
            } else {
              this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true
              })
            }
          });

        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    jumpLogin() {
      this.$router.push("/login")
    }
  }
}