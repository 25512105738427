// import Driver from "@/common/driver/driver.min.js";
import { driver } from "driver.js";
export default {
  name: 'NewGuideComponent',
  data() {
    return {
      // 场景配置
      sceneConfig: {
        visible: false,
        checked: '',
        list: []
      },
      // 步骤概览当前步骤
      currentOverviewStep: '',
      // 步骤概览
      showStepOverview: false,
      // 步骤概览配置（对应页面是否显示）
      stepOverviewConfig: null,
      // 向导描述配置
      guideDescConfig: null,
      btnLoading: false,
      locList: [],
      accountList: [],
      rcwoList: [],
    };
  },
  computed: {
    // 禁用阶段的确认按钮，没有巡点或没有账号时禁用
    isBtnDisabled() {
      return !this.locList.length || !this.accountList.length
    },
    // 获取当前行业的描述
    getCurrentIndustryDesc() {
      let match = this.sceneConfig.list.find(l => l.value === this.sceneConfig.checked)
      return match ? match.description : ''
    },
    // 步骤概览
    stepOverviewList() {
      let permission = [
        {
          key: 'create_property',
          show: this.$store.getters.selfregister,
        }, {
          key: 'create_account',
          show: this.$store.getters.selfregister || this.$store.getters.isOperate,
        },
        {
          key: 'create_location',
          show: this.$store.getters.selfregister || this.$store.getters.isOperate,
        },
        {
          key: 'create_plan',
          show: this.$store.getters.selfregister || this.$store.getters.isOperate || this.$store.getters.ispemgr,
        },
        {
          key: 'download_app',
          show: this.$store.getters.selfregister || this.$store.getters.isOperate || this.$store.getters.ispemgr,
        },
      ]
      const showList = permission.filter(pItem => pItem.show)
      return showList.map((sItem, index) => ({
        ...sItem,
        label: (index + 1) + '. ' + this.guideDescConfig.step['stepOverview_' + sItem.key],
      }))
    },
    // 步骤概览内容
    stepOverviewContent() {
      let opt = {
        create_property: {
          img: require('@/assets/guide/step-property.svg'),
          title: [this.guideDescConfig.step.step1, this.guideDescConfig.step.create_property_title],
          content: [this.guideDescConfig.step.create_property_content1, this.guideDescConfig.step.create_property_content2]
        },
        create_account: {
          img: require('@/assets/guide/step-account.svg'),
          title: [this.guideDescConfig.step.step2, this.guideDescConfig.step.create_account_title],
          content: [this.guideDescConfig.step.create_account_content1, this.guideDescConfig.step.create_account_content2, this.guideDescConfig.step.create_account_content3]
        },
        create_location: {
          img: require('@/assets/guide/step-location.svg'),
          title: [this.guideDescConfig.step.step3, this.guideDescConfig.step.create_location_title],
          content: [this.guideDescConfig.step.create_location_content1, this.guideDescConfig.step.create_location_content2]
        },
        create_plan: {
          img: require('@/assets/guide/step-insp-plan.svg'),
          title: [this.guideDescConfig.step.step4, this.guideDescConfig.step.create_plan_title],
          content: [this.guideDescConfig.step.create_plan_content1, this.guideDescConfig.step.create_plan_content2, this.guideDescConfig.step.create_plan_content3]
        },
        download_app: {
          img: require('@/assets/guide/step-app.svg'),
          title: [this.guideDescConfig.step.step5, this.guideDescConfig.step.download_app_title],
        },
      }
      // 动态更换步骤序号描述
      this.stepOverviewList.forEach((item, index) => {
        opt[item.key].title[0] = this.guideDescConfig.step['step' + (index + 1)]
      })
      return opt
    }
  },
  mounted() {
    if (!window.guideDriver) {
      this.initDriver()
    }
    // 获取向导描述，场景配置和场景列表
    const init = async () => {
      try {
        await this.getGuideDesc()
        await this.getGuideSetting()
        this.$pubsub.publish('getGuideDescAndSettingFulfilledEvent')
        this.getIndustryList()
        if (this.$route.path === '/home') {
          // 如果没有场景就显示选择场景弹窗
          if (!this.sceneConfig.checked) {
            if (!this.guideDescConfig) return
            this.sceneConfig.visible = true
          } else {
            // 未完成对应数量的巡检单,且走过一遍向导全部流程，就显示教程视频弹窗
            // this.stepOverviewConfig.showRctaskGuide： 是否还没完成单子对应数量
            if (this.stepOverviewConfig && this.stepOverviewConfig.showRctaskGuide) {
              await this.getloclist()
              await this.getaccountlist()
              await this.getRcwoList()
              // 非演示物业，有账号，巡点巡检计划
              if (!this.$store.getters.isDemo && this.locList.length > 0 && this.accountList.length > 0 && this.rcwoList.length > 0) {
              this.$pubsub.publish('showTutorialVideoEvent')
              } else {
                let registerOrAdmin = this.$store.getters.selfregister || this.$store.getters.isOperate
                // 对应步骤的权限
                let permission = {
                  create_property: this.$store.getters.selfregister,
                  create_account: registerOrAdmin && !this.$store.getters.isDemo,
                  create_location: registerOrAdmin && !this.$store.getters.isDemo,
                  create_plan: (registerOrAdmin || this.$store.getters.ispemgr) && !this.$store.getters.isDemo,
                  download_app: registerOrAdmin || this.$store.getters.ispemgr
                }
                // 先判断是否有权限，有权限再匹配当前页面向导
                for (const key of Object.keys(permission)) {
                  if (permission[key]) {
                    // 提取第一个符合条件的权限键
                    let permissionKey = Object.keys(permission).find(key => permission[key]);
                    this.$pubsub.publish('overviewStepEvent', {key: permissionKey, show: true})
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    init()
    // 切换语言时，需要重新初始化向导和重新获取数据
    this.$setLanguageChangeCallback([this.initDriver, init])
    this.$pubsub.subscribe('showSceneDialogEvent', () => {
      this.showSceneDialog()
    })
    // 监听步骤概览的步骤变化，data有show的话强制显示
    this.$pubsub.subscribe('overviewStepEvent', async (msg, data) => {
      const obj = {
        'create_property': 'showPropertyEntityGuide',
        'create_account': 'showAccountGuide',
        'create_location': 'showLocationGuide',
        'create_plan': 'showRctaskGuide',
        'download_app': 'showDownloadApp',
      }

      let registerOrAdmin = this.$store.getters.selfregister || this.$store.getters.isOperate
      // 对应步骤的权限
      let permission = {
        create_property: this.$store.getters.selfregister,
        create_account: registerOrAdmin && !this.$store.getters.isDemo,
        create_location: registerOrAdmin && !this.$store.getters.isDemo,
        create_plan: (registerOrAdmin || this.$store.getters.ispemgr) && !this.$store.getters.isDemo,
        download_app: registerOrAdmin || this.$store.getters.ispemgr
      }
      // 判断传入的步骤是否有权限，有：直接赋值 无：拿第一个符合条件的权限键
      if (permission[data.key]) {
        this.currentOverviewStep = data.key
      } else {
        let permissionKey = Object.keys(permission).find(key => permission[key]);
        this.currentOverviewStep = permissionKey
      }
      // 获取对应页面是否显示步骤概览
      if ((this.stepOverviewConfig && this.stepOverviewConfig[obj[this.currentOverviewStep]]) || data.show || this.$store.getters.showGuideStage) {
        this.showStepOverview = true
        // 如果是创建计划或下载app，按钮先loading，等请求完成才能点击
        if (this.currentOverviewStep === 'create_plan' || this.currentOverviewStep === 'download_app') {
          this.btnLoading = true
          try {
            await this.getloclist()
            await this.getaccountlist()
          } catch (error) {
            console.log('error', error);
          } finally {
            this.btnLoading = false
          }
          }
        }

    })
  },
  beforeDestroy() {
    window.guideDriver && window.guideDriver.destroy()
    this.$pubsub.unsubscribe('showSceneDialogEvent')
    this.$pubsub.unsubscribe('overviewStepEvent')
    this.$pubsub.unsubscribe('resetGuideSteps')
  },
  methods: {
    // 获取行业列表
    getIndustryList(cb) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/common/databind', {
            hrid: this.$store.getters.loginid,
            penum: this.$store.getters.penum,
            databindid: 'GUIDE_INDUSTRY',
          }).then((response) => {
            if (response.data.success) {
              let list = response.data.content
              list.forEach(item => {
                item.value = item.value.toLowerCase();
              })
              this.sceneConfig.list = list
              cb && cb()
              resolve()
            } else {
              this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true
              })
              reject()
            }
          })
      })
    },
    // 获取向导配置
    getGuideSetting() {
      return new Promise((resolve, reject) => {
        this.$http.post('/v2/guide/info', {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum
        }).then((res) => {
          if (res.data.success) {
            const type = (res.data.content.setting.type || '').toLowerCase()
            this.stepOverviewConfig = res.data.content
            this.sceneConfig.checked = type
            this.$store.commit('SET_GUIDE_SCENE', type)
            resolve()
          } else {
            this.$alert(res.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
            reject()
          }
        }).catch((err) => { reject(err) })
      })
    },
    // 设置向导场景，type是对应场景
    setGuideScene(type) {
      return new Promise((resolve, reject) => {
        this.$http.post('/v2/guide/setting/save', {
          hrid: this.$store.getters.loginid,
          type
        }).then((res) => {
          if (res.data.success) {
            this.$store.commit('SET_GUIDE_SCENE', type)
            // 切换场景完成事件
            this.$pubsub.publish('setGuideSceneFulfilledEvent')
            resolve()
          } else {
            this.$alert(res.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
            reject()
          }
        }).catch((err) => { reject(err) })
      })
    },
    // 变更步骤
    async changeStep(step) {
      this.currentOverviewStep = step.key
      if (step.key === 'create_plan' || step.key === 'download_app') {
        console.log('this.isBtnDisabled', this.isBtnDisabled)
        try {
          this.btnLoading = true
          await this.getloclist()
          await this.getaccountlist()
        } catch (e) {
          console.log(e)
        } finally {
          this.btnLoading = false
        }
      }
    },
    // 步骤概览确认按钮
    async stepConfirm() {
      // 处于演示物业且注册账号，提醒创建物业
      if (this.$store.getters.isDemo && this.$store.getters.selfregister && this.currentOverviewStep !== 'create_property') {
        await this.setGuideScene(this.sceneConfig.checked)
        // 需要重设步骤的页面
        let resetGuideStepsPages = ['/property_list', '/account', '/location', '/create']
        if (resetGuideStepsPages.includes(this.$route.path)) {
          await this.resetGuideSteps()
        }
        this.demoPeCloseGuideCallback()
        return
      }

      this.btnLoading = true
      // download_app 阶段不用获取场景和重设步骤
      if (this.currentOverviewStep !== 'download_app') {
        try {
          await this.setGuideScene(this.sceneConfig.checked)
          // 需要重设步骤的页面
          let resetGuideStepsPages = ['/property_list', '/account', '/location', '/create']
          if (resetGuideStepsPages.includes(this.$route.path)) {
            await this.resetGuideSteps()
          }
        } catch (error) {
          console.log('error', error);
        } finally {
          this.btnLoading = false
        }
        // create_plan 需要在他的处理是才关，有其他逻辑
        if (this.currentOverviewStep !== 'create_plan') {
          this.showStepOverview = false
        }
      }
      this.$store.commit('SET_SHOW_GUIDE_STAGE', false)
      this.$store.commit('SET_ISSHOWGUIDE', true)
      let code = this.$store.getters.lang || 'zht'
      let langOpt = {
        zh: 'sc',
        zht: 'tc',
        tw: 'tw',
        en: 'en',
      }

      let _this = this
      // 通过postMessage传数据
      async function to313fm() {
        try {
          await _this.getpedetail()
          await _this.getaccountlist()
          await _this.getloclist()
          const originList = ['http://127.0.0.1:5500', 'https://www.313fm.com']
          let targetOrigin = 'https://www.313fm.com'
          let window_313fm = window.open(`${targetOrigin}/${langOpt[code]}/user-flow.html`, '_blank')
          let guideData = {
            peAbbr: sessionStorage.getItem('guide_peabbr'),
            account: sessionStorage.getItem('guide_account'),
            loc: sessionStorage.getItem('guide_loc') ? JSON.parse(sessionStorage.getItem('guide_loc')) : [],
          }

          const messageHandler = (event) => {
            if (originList.indexOf(event.origin) > -1) {
              console.log('event', event);
              if (event.data && event.data === 'loaded') {
                window_313fm.postMessage(guideData, targetOrigin)
                // 先不清除，清楚了的话，官网那边刷新页面会获取不到数据了
                // window.removeEventListener('message', messageHandler)
              }
            }
          }

          if (window_313fm) {
            window.addEventListener('message', messageHandler)
          } else {
            // 弹窗被阻止了，提示用户允许弹窗再操作
            _this.$alert(_this.$t('guide.notAllowPopupTips'), _this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }

        } catch (e) {
          console.log(e)
        } finally {
          _this.btnLoading = false
          _this.showStepOverview = false
        }
      }
        switch (this.currentOverviewStep) {
          case 'create_property':
            if (this.$route.path == '/property_list') {
              let createBtn = document.querySelector('#create-pe-btn')
              createBtn && createBtn.scrollIntoView({block: 'nearest'})
              window.guideDriver.drive()
            } else {
              this.$router.push('/property_list')
            }
            break;
          case 'create_account':
            if (this.$route.path !== '/account') {
              window.guideDriver.setSteps([{
                element: '#account_menu', popover: {
                  showButtons: ['next'],
                  nextBtnText: this.$t('guide.goOpen'),
                  progressText: '',
                  description: this.guideDescConfig.account.create_account_tips_menu,
                  side: 'right',
                  align: 'center',
                  onNextClick: () => {
                    window.guideDriver.destroy()
                    this.$router.push('/account')
                  }
                },
              },])
              this.$pubsub.publish('openMenuEvent', 'propertyEntity')
              this.$nextTick(() => {
                setTimeout(() => {
                  window.guideDriver.drive()
                }, 350)
              })
            } else {
              let createBtn = document.querySelector('#account_create_btn')
              createBtn && createBtn.scrollIntoView({block: 'nearest'})
              window.guideDriver.drive()
            }
            break;
          case 'create_location':
            if (this.$route.path !== '/location') {
              window.guideDriver.setSteps([{
                element: '#location_menu', popover: {
                  showButtons: ['next'],
                  nextBtnText: this.$t('guide.goOpen'),
                  progressText: '',
                  description: this.guideDescConfig.location.create_location_tips_menu,
                  side: 'right',
                  align: 'center',
                  onNextClick: () => {
                    window.guideDriver.destroy()
                    this.$router.push('/location')
                  }
                },
              },])
              this.$pubsub.publish('openMenuEvent', 'propertyEntity')
              this.$nextTick(() => {
                setTimeout(() => {
                  window.guideDriver.drive()
                }, 350)
              })
            } else {
              let createBtn = document.querySelector('#location_create_btn')
              createBtn && createBtn.scrollIntoView({block: 'nearest'})
              window.guideDriver.drive()
            }
            break;
          case 'create_plan':
            if (this.isBtnDisabled) {
              let msg = !this.accountList.length ? this.$t('guide.noAccountTips') : !this.locList.length ? this.$t('guide.noLocTips') : ''
              this.$alert(msg, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true,
              })
              this.btnLoading = false
              return
            }
            this.showStepOverview = false
            if (this.$route.path !== '/create') {
              window.guideDriver.setSteps([{
                element: '#insp_plan_menu', popover: {
                  showButtons: ['next'],
                  nextBtnText: this.$t('guide.goOpen'),
                  progressText: '',
                  description: this.guideDescConfig.plan.create_plan_tips_menu,
                  side: 'right',
                  align: 'center',
                  onNextClick: () => {
                    window.guideDriver.destroy()
                    this.$router.push('/create')
                  }
                },
              },])
              this.$pubsub.publish('openMenuEvent', 'InspectionPlan')
              this.$nextTick(() => {
                setTimeout(() => {
                  window.guideDriver.drive()
                }, 350)
              })
            } else {
              window.guideDriver.drive()
            }
            break;
          case 'download_app':
            if (this.isBtnDisabled) {
              let msg = !this.accountList.length ? this.$t('guide.noAccountTips') : !this.locList.length ? this.$t('guide.noLocTips') : ''
              this.$alert(msg, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true,
              })
              this.btnLoading = false
              return
            }
            this.$store.commit('SET_ISSHOWGUIDE', false)
            to313fm()
            break;
          default:
            break;
        }
    },
    // 步骤概览关闭前处理
    stepOverviewBeforeClose() {
      this.$store.commit('SET_ISSHOWGUIDE', false)
      this.$store.commit('SET_SHOW_GUIDE_STAGE', false)
      this.showStepOverview = false
      this.btnLoading = false
      this.locList = []
      this.accountList = []
      if (this.$store.getters.isDemo) {
        this.demoPeCloseGuideCallback()
      } else {
        if (this.$route.path === '/home') {
          window.guideDriver.setSteps([
            {
              element: '#guide_btns',
              popover: {
                showButtons: ['next'],
                nextBtnText: this.$t('guide.understand'),
                popoverClass: 'no-close',
                progressText: '',
                description: this.guideDescConfig.home.guide_description,
                side: 'bottom',
                align: 'start',
              }
            }
          ])
          let guideBtns = document.querySelector('#guide_btns')
          guideBtns && guideBtns.scrollIntoView({block: 'nearest'})
          window.guideDriver.drive()
        } else {
          this.$pubsub.publish('showGuidePositionEvent', this.guideDescConfig)
        }
      }
    },
    // 初始化向导
    initDriver() {
      console.log('初始化向导');
      window.guideDriver = driver({
        progressText: '{{current}} / {{total}}',
        allowClose: false,
        allowKeyboardControl: false,
        showProgress: true,
        showButtons: ['next', 'previous'],
        nextBtnText: this.$t('guide.next'),
        prevBtnText: this.$t('guide.previous'),
        doneBtnText: this.$t('guide.ok'),
        onPopoverRender: (popover) => {
          // 创建关闭按钮
          const closeBtn = document.createElement('button')
          closeBtn.classList.add('custom-close-btn')
          closeBtn.textContent = this.$t('common.close')
          closeBtn.onclick = () => {
            window.guideDriver.destroy()
            this.$store.commit('SET_ISSHOWGUIDE', false)
            if (this.$store.getters.isDemo) {
              this.demoPeCloseGuideCallback()
            } else {
              this.$pubsub.publish('showGuidePositionEvent', this.guideDescConfig)
            }
          }
          // 将关闭按钮插到最前面
          popover.footer.insertBefore(closeBtn, popover.footer.firstChild)
        }
      });
    },
    // 获取向导描述
    getGuideDesc() {
      return new Promise((resolve, reject) => {
        this.$http.post('/v2/guide/list', {
          hrid: this.$store.getters.loginid,
          langcode: this.$store.getters.lang,
          pageName: '',
          type: '',
        }).then(res => {
          if (res.data.success) {
            let config = {}
            res.data.content.forEach(c => {
              config[c.pageName] = {}
              c.steps.forEach(s => {
                config[c.pageName][s.type] = s.description
              })
            })
            this.guideDescConfig = config
            this.$store.commit('SET_GUIDE_DESCRIPTION', config)
            // 获取描述完成
            // this.$pubsub.publish('guideDescriptionFulfilledEvent', config)
            resolve()
          } else {
            this.$alert(res.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
            reject()
          }
        }).catch((err) => { reject(err) })
      })
    },
    // 确认场景
    async sceneConfirm() {
      try {
        await this.setGuideScene(this.sceneConfig.checked)
      } catch (error) {
        console.log('err', error);
      }
      this.sceneConfig.visible = false
      // this.$store.commit('SET_SHOW_GUIDE_STAGE', true)

      let path = this.$route.path.split('/')[1]
      let registerOrAdmin = this.$store.getters.selfregister || this.$store.getters.isOperate
      // 对应步骤的权限
      let permission = {
        create_property: this.$store.getters.selfregister,
        create_account: registerOrAdmin && !this.$store.getters.isDemo,
        create_location: registerOrAdmin && !this.$store.getters.isDemo,
        create_plan: (registerOrAdmin || this.$store.getters.ispemgr) && !this.$store.getters.isDemo,
        download_app: registerOrAdmin || this.$store.getters.ispemgr
      }
      // 页面对应的步骤总览步骤
      let pageOverviewStep = {
        create_property: /^(property_list|basicinfo)$/,
        create_account: /^account$/,
        create_location: /^location$/,
        create_plan: /^create$/,
        download_app: /.*/, // 兜底，其他页面
      }
      // 先判断是否有权限，有权限再匹配当前页面向导
      for (const key of Object.keys(permission)) {
        if (permission[key] && pageOverviewStep[key].test(path)) {
          if (key === 'download_app') {
            // 提取第一个符合条件的权限键
            let permissionKey = Object.keys(permission).find(key => permission[key]);
            this.$pubsub.publish('overviewStepEvent', {key: permissionKey, show: true})
          } else {
            this.$pubsub.publish('overviewStepEvent', {key, show: true})
          }
          return
        }
      }
    },
    // 场景选择弹窗关闭前
    sceneBeforeClose() {
      this.sceneConfig.visible = false
        // 如果没有确认选中场景，就弹出对应向导提示
        if (!this.$store.getters.guideScene) {
          if (this.$route.path === '/home') {
          window.guideDriver.setSteps([
            {
              element: '#guide_btns',
              popover: {
                showButtons: ['next'],
                nextBtnText: this.$t('guide.understand'),
                popoverClass: 'no-close',
                progressText: '',
                description: this.guideDescConfig.home.guide_description,
                side: 'bottom',
                align: 'start',
              }
            }
          ])
            let guideBtns = document.querySelector('#guide_btns')
            guideBtns && guideBtns.scrollIntoView({block: 'nearest'})
            window.guideDriver.drive()
          } else {
            this.$pubsub.publish('showGuidePositionEvent', this.guideDescConfig)
          }
        } else {
          if (this.$store.getters.isDemo) {
            this.demoPeCloseGuideCallback()
          }
        }
    },
    // 演示物业关闭向导回调
    demoPeCloseGuideCallback() {
      this.showStepOverview = false
      this.$confirm(this.guideDescConfig.home.no_pe_detected, this.$t('common.alertPrompt'), {
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString: true,
        cancelButtonText: this.guideDescConfig.home.step_create_later,
        confirmButtonText: this.guideDescConfig.home.step_create_now,
      }).then(() => {
        // 如果有场景，就打开向导，否则弹出场景选择
        if (this.$store.getters.guideScene) {
          this.$store.commit('SET_ISSHOWGUIDE', true)
          // 在当前页就启用向导，否则跳转至物业列表
          if (this.$route.path === '/property_list') {
            this.$pubsub.publish('closeDrawerEvent')
            window.guideDriver.drive()
          } else {
            this.$router.push('/property_list')
          }
        } else {
          this.showSceneDialog()
        }

      }).catch(() => {
        if (this.$route.path === '/home') {
          window.guideDriver.setSteps([
            {
              element: '#guide_btns',
              popover: {
                showButtons: ['next'],
                nextBtnText: this.$t('guide.understand'),
                popoverClass: 'no-close',
                progressText: '',
                description: this.guideDescConfig.home.guide_description,
                side: 'bottom',
                align: 'start',
              }
            }
          ])
          let guideBtns = document.querySelector('#guide_btns')
          guideBtns && guideBtns.scrollIntoView({block: 'nearest'})
          window.guideDriver.drive()
        } else {
          this.$pubsub.publish('showGuidePositionEvent', this.guideDescConfig)
        }
      });
    },
    // 获取物业详情
    getpedetail() {
      this.$http.post('/property/pedetail', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
      }, {showLoading: false}).then((response) => {
        if (response.data.success) {
          sessionStorage.setItem('guide_peabbr', response.data.content.peab)
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      }).catch(() => {
        // 错误
        this.$alert(this.$t('common.requestCheckConnected'), this.$t('common.alertPrompt'))
      });
    },
    // 获取用户列表
    getaccountlist() {
      return new Promise((resolve, reject) => {
        this.$http
            .post('/account/accountlist', {
              hrid: this.$store.getters.loginid,
              penum: this.$store.getters.penum,
              query: '',
              page: 1,
              pagesize: 10,
            }, {
              showLoading: false
            })
            .then((response) => {
              if (response.data.success) {
                this.accountList = response.data.content
                if (Array.isArray(response.data.content) && response.data.content.length > 0) {
                  sessionStorage.setItem('guide_account', response.data.content[0].roleaccount)
                }
                resolve()
              } else {
                this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true
                })
                reject()
              }
            })
      })
    },
    // 获取有效的巡检点
    getloclist() {
      return new Promise((resolve, reject) => {
        this.$http
            .post('/location/loclist', {
              hrid: this.$store.getters.loginid,
              penum: this.$store.getters.penum,
              query: '',
              page: 1,
              pagesize: 10,
              active: '1', // 有效的巡点
            }, {
              showLoading: false
            }).then(async (response) => {
          if (response.data.success) {
            this.locList = response.data.content
            let list = []
            try {
              for (const loc of response.data.content) {
                if (loc.qrcode) {
                  let result = await this.$utils.getBase64(loc.qrcode, {showLoading: false})
                  list.push({
                    name: loc.description,
                    qrcode: result
                  })
                }
              }
              sessionStorage.setItem('guide_loc', JSON.stringify(list))
            } catch (error) {
              console.log('error', error);
            }

            resolve()
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
            reject()
          }
        })
      })

    },
    // 获取巡检单
    getRcwoList() {
      return new Promise((resolve, reject) => {
        this.$http
            .post("/rcwo/rcwolist", {
              hrid: this.$store.getters.loginid,
              penum: this.$store.getters.penum,
              query: '',
              querytype: '4',
              page: 1,
              pagesize: 10,
              status: '',
              startdate: '',
              enddate: '',
              rcwoType: "RCWO",
            }, {showLoading: false})
            .then((response) => {
              if (response.data.success) {
                this.rcwoList = response.data.content;
                resolve()
              } else {
                this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true
                })
                reject()
              }
            })
      })
    },
    // 重设步骤
    resetGuideSteps() {
      return new Promise((resolve) => {
        this.$pubsub.subscribe('resetGuideSteps', (msg, steps) => {
          window.guideDriver.setSteps(steps)
          // 每次都要清空，防止触发多次（因为在同一个页面可能会多次弹出阶段弹窗，会导致触发多次）
          this.$pubsub.unsubscribe('resetGuideSteps')
          resolve()
        })
      })
    },
    // 显示场景弹窗
    showSceneDialog() {
      this.sceneConfig.visible = true
    },
  }
}