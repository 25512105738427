import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const lang = {
  ...zhLocale,
  common: {
    alertPrompt: '提示',
    requestCheckConnected: '服务器繁忙，请稍候重试！',
    updateInstruction: '更新说明',
    operation: '操作',
    yes: '是',
    no: '否',
    sort: '排序',
    copy: '复制',
    confirm: '确认',
    sure: '确定',
    cancel: '取消',
    save: '保存',
    detail: '详细',
    viewDetail: '查看详细',
    add: '新增',
    create: '创建',
    import: '导入',
    view: '查看',
    set: '设置',
    delete: '删除',
    deleteAll: '全部删除',
    publish: '发布',
    preview: '预览',
    imageUploadTip: '图片大小应在{size}以内',
    videoUploadTip: '视频大小应在{size}以内',
    attachmentUploadTip: '附件大小应在{size}以内',
    photos: '照片',
    select: '选择',
    clear: '清除',
    back: '返回',
    stop: '停止',
    edit: '编辑',
    change: '更换',
    start: '开始',
    hour: '小时',
    minute: '分钟',
    day: '天',
    noSupportTips: '当前版本不支持该功能，如需使用，请升级版本，如有疑问，请联系客服。',
    valid: '有效',
    inValid: '无效',
    timeOut: '超时',
    enable: '启动',
    close: '关闭',
    all: '全部',
    to: '到',
    more: '更多',
    send: '发送',
    ok: '好的',
    submit: '提交',
    copyrightDesc: 'Copyright © 2024 SAMEX 深圳市赛美科斯软件系统开发有限公司版权所有',
    seleted: '已选择',
    pleaseSelect: '请选择',
    startTime: '开始时间',
    endTime: '结束时间',
    timeTo: '至',
    pageTotal: '共有',
    pageTotalRecord: '条记录',
    pageTotalRecords: '条记录',
    nightMode: '黑色主题',
    noData: '暂无数据',
    selectStatusPlh: '请选择状态',
    refreshList: '刷新列表',

    // 短信错误信息、发送验证码失败的提示
    ERR_NOTICE_0000: '发送失败',
    ERR_NOTICE_0001: ' 为无效号码',
    ERR_NOTICE_0002: '运营商限流',
    CFG_NOTICE_0003: '没有足够的短信包数量或未开启短信通知',
    CFG_NOTICE_0004: '没有设置所需的区号或电话号码',
    CFG_NOTICE_0005: '未开通发送区域权限',

    uploadImageFileTips: '请上传图片文件！',
    uploadVideoFileTips: '请上传视频文件！',
    fileUploadFailed: '{name} 上传失败！',

    backConfirmTips: '数据有更改且尚未保存，请确认是否返回？',
  },
  guideBox: {
    nextStep: '下一步',
    preStep: '上一步',
    androidDownload: 'Android版下载',
    iosDownload: 'iOS版下载 ',
    guideFinish: '完成',
    guideAccountRepeatedly: '是否继续创建账号',
    guidePointSrepeatedly: '是否继续创建巡检点',
  },
  webLayout: {
    webSiteHome: '网站首页',
    locCountLabel: '个巡检点',
    cardExpireDateLabel: '有效期至',
    onLineService: '在线客服',
    help: '帮助',
    releaseHistory: '版本历史记录',
    showGuideName: 'AI 助理',
    ViewTutorialVideos: '查看教程视频',
    logOut: '退出登录',
    navHome: '首页',
    navInspection: '巡检监控',
    navSafetyIncident: '安全事件',
    navProperty: '物业实体',
    navAccount: '账号信息',
    navNotification: '通知管理',
    navApp: 'APP下载',
    navSystem: '系统设置',
    navSSO: 'SSO设置',
    navCommonReasonManagement: '常见理由管理',
    navSla: 'SLA 配置',
    navVisitorQuestionTmpl: '访客问题模板',
    classManagement: '分类管理',
    vipExperience: '体验卡',
    vipGreen: '绿卡',
    vipSilver: '银卡',
    vipGold: '金卡',
    androidDownload: 'Android版下载',
    iosDownload: 'iOS版下载',

    collapseMenu: '折叠菜单',
    propertyEntity: '物业实体',
    entityDetail: '基本信息',
    userAccounts: '用户账户',
    safetyGuard: '安全卫士',
    authorizationManagement: '授权管理',
    workGroups: '工作组',
    accessGroups: '用户组权限',
    inspectionPoints: '巡检点',
    holidayManagement: '假期管理',
    assetLedger: '资产台账',
    iotSensors: '物联网传感器',
    inspectionPlan: '巡检计划',
    createInspectionPlan: '创建巡检计划',
    inspectionPlanManagement: '巡检计划管理',
    sopManagement: '常用项管理',
    workOrderTemplate: '工单模板',
    workOrderTemplateManagement: '工单模板管理',
    punchInTemplate: '打卡模板',
    signInTemplateManagement: '打卡签到模板管理',
    monitorTemplate: '监控模板',
    monitorTemplateManagement: '监控模板管理',
    historicalRecords: '历史记录',
    inspectionOrderRecords: '巡检单记录',
    workOrderRecords: '工单记录',
    signInRecord: '打卡签到记录',
    iotSensorList: '物联网传感器记录',
    incidentOrderRecords: '事件报告记录',
    dataMeterReadings: '数据 / 仪表读数',
    alertsHistory: '警报历史',
    visitorManagement: '访客管理',
    receptionEntranceManagement: '访客接待 / 入口管理',
    visitorAppointments: '访客预约记录',
    visitorVisitRecords: '访客来访历史',
    supportingFunctions: '辅助功能',
    dutyLogbook: '岗位日志',
    documentManagement: '文档管理',
    documentUpload: '文档上传',
    documentAccessRecords: '文档访问记录',
    myDocuments: '我的文档',
    statisticsAnalysis: '统计分析',
    reportCenter: '报表中心',
    inspectionEfficiencyDashboard: '巡检执行效率仪表板',
    incidentReportDashboard: '事件报告仪表板',
    workOrderResponseTimeDashboard: '工单响应仪表板',
    abnormalityDashboard: '巡查异常仪表板',
    humanEfficiencyDashboard: '人员效率仪表板',
    inventoryMaterial: '库存物料',
    inventoryManagement: '库存管理',
    materialUsageRecord: '物料使用记录',
    switchPropertySuccess: '物业实体切换成功！',
    switchLangSuccess: '语言切换成功！',
    rentalManagement: '借还管理',
    rentPropertyList: '物件清单',
    lendReturn: '借用 / 归还',
    disposalOrder: '报废处置单',
  },
  login: {
    loginBannerHomeLabel: '物联网（IoT）传感模块',
    loginBannerHomeTitle1: '智慧感知，智控未来，',
    loginBannerHomeTitle2: '设施设备尽在掌控！',
    loginBannerHomeLi1: '实时状态监控',
    loginBannerHomeLi2: '智能巡检报警',
    loginBannerHomeLi3: '实时工单调度',

    loginBannerInventoryLabel: '库存管理模块',
    loginBannerInventoryTitle1: '微物料，大影响，',
    loginBannerInventoryTitle2: '核心因之而安。',
    loginBannerInventoryLi1: '无缝申请',
    loginBannerInventoryLi2: '灵活分配',
    loginBannerInventoryLi3: '实时库存控制',

    loginBannerVisitorLabel: '访客管理模块',
    loginBannerVisitorTitle1: '零纸化新体验，安全高效，',
    loginBannerVisitorTitle2: '访客到访轻松便捷！',
    loginBannerVisitorLi1: '无纸化登记。',
    loginBannerVisitorLi2: '无需安装APP。',
    loginBannerVisitorLi3: '安全管控，有序进入。',

    loginTitle: '专业的巡检云平台',
    logIn: '登  录',
    contactUs: '联系我们',
    phoneLogin: '手机登录',
    configPhone: '手机号',
    accountPass: '密码',
    accLogin: '账号登录',
    peshortName: '物业实体简称',
    accountId: '登录账号',
    hybrid: '第三方登录',
    email: '第三方账号',
    vercodeTips: '请输入图形验证码',
    freeregnow: '免费注册',
    forgotPass: '忘记密码？',
    otherLoginMethods: '其他登陆方式',

    // 登录页面的联系方式
    labelSg: '新加坡：',
    labelAu: '澳大利亚：',
    labelHk: '香港：',
    labelTw: '台湾：',
    labelCn: '中国大陆：',

    loginAreaCodeTips: '请选择区号',
    loginPhoneTips: '请输入手机号',
    loginPasswordTips: '请输入密码',
    loginPeabbrTips: '请输入物业实体简称',
    loginUserAccountTips: '请输入角色账号',
    loginHybridAccountTips: '请输入登录账号',
    loginHybridImgCodeTips: '请输入图形验证码',

    webSiteVersion: '您的账号属于另一个版本网站（{anotherSite}），系统已帮您转到了该网站，请重新登录。',
  },
  register: {
    welcomeReg: '欢迎注册',
    chinesePhoneTips: '建议使用常用手机号码',
    configPass: '密码',
    rePass: '重复密码',
    phoneCode: '手机验证码',
    regSuccessMsg: '注册成功，点击“确定”马上登录',
    immReg: '免费注册',
    sendCode: '发送验证码',
    inputCodePlh: '请输入验证码',
    inputCodeError: '请输入验证码',
    sendCodeBtn: '发送验证码',
    sendCodeBtning: '{seconds}秒',
    phoneCodeTips: '请点击发送验证码按钮，我们将发送短信验证码去注册的号码上',
    rePassTips: '请重复输入相同的密码',
    configNewPassFormat: '请输入6-20位数字、字母或特殊符号，特殊符号仅支持@#&*_',
    configNewPassFormatTip: '请输入6-20位数字、字母或特殊符号，特殊符号仅支持@#&*_',
    tips_402001: '此账号已注册，请直接登录',
    existAccountTip: '此账号已经注册，是否前往登录？',
    goToLoginLabel: '前往登录',
    forgetPwdTip: '如若忘记密码，可以前往忘记密码页面找回密码',
    retrievePwdLabel: '找回密码',
    goUseFlowTip: '您也可以前往使用流程页面，进行学习和操作',
    useFlowLabel: '使用流程',
  },
  forgot: {
    loginTitle: '专业的巡检云平台',
    forgotPass: '忘记密码？',
    verAcc: '验证账号',
    modPass: '修改密码',
    resComp: '重置完成',
    configPhone: '手机号',
    verCode: '图形验证码',
    phoneCode: '手机验证码',
    nextStep: '下一步',
    verCodeTips: '请输入图形验证码',
    phoneCodeTips: '请输入手机验证码',
    phoneIsNotExist: '该号码不存在',
    correctImageCode: '请输入正确的图形验证码',
    correctCode: '验证码错误，请重新输入或再次发送',
    sendCodeSuccess: '手机验证码已成功发送到你手机',
    newPass: '新密码',
    configNewPassformat: '请输入6-20位数字、字母或特殊符号，特殊符号仅支持@#&*_',
    repNewPassTips: '请再次输入密码',
    repNewPass: '重复新密码',
    passIsConsistent: '两次输入的密码不一致',
    modSuccessMsg: '您的密码已重置。请记住您的新密码。',
    modSuccessMsgJumpMsg: '点击跳转到 {loginPage}.',
    // modSuccessMsgJumpMsg: '{seconds} 秒后跳转到 {loginPage}.',
    loginPage: '登录页',
    contactPhone: '在线客服 18320886254',
    home: '首页',
    login: '登录',
  },
  forgotAccount: {
    peShortName: '物业实体简称',
    peShortNameTips: '请输入物业实体简称',
    accountId: '登录账号',
    accountIdTips: '请输入登录账号',
    verCode: '验证码',
    verCodeTips: '请输入图形验证码',
    peAdminPhone: '物业实体管理员电话',
    peAdminPhoneTips: '请输入管理员手机号',
    phoneNumError: '手机号不正确',
    correctImageCode: '请输入正确的图形验证码',
    accmodSuccessMsg: '密码重设成功，新密码已发送至号码：',
  },
  selectFromTemplate: {
    selMulTemTit: '从模板选择多选项',
    enterSearch: '请输入模板名称',
  },
  saveOptionsTemplate: {
    saveMulTem: '将多选项保存为模板',
    temName: '模板名称',
    checkSelectVal: '请添加可选项！',
    checkTemNamePlh: '请输入模板名称',
    checkTemName: '请输入模板名称',
    checkTemNameExceed: '模板名称不能超过20字',
    saveSuccess: '选项模板保存成功！',
  },
  home: {
    homeTitle: '概述',
    setting: '设置',
    allProperty: '所有物业',
    homeAccountData: '账号数据',
    homeAccountDataAll: '所有物业',
    homeAccountDataCurrent: '{property}',
    proentity: '物业实体',
    homeAccount: '使用账号',
    homeLoc: '巡检点',
    instask: '巡检计划',
    woTemplate: '工单模板',
    homeRcWoTit: '巡检单执行情况汇总',
    homeWoTit: '工单执行情况汇总',
    homeIrTit: '事件报告执行情况汇总',

    homeCancel: '取消',
    homeCompleted: '已完成',
    homeExecuting: '进行中',
    homeTimeout: '超时',
    homeToExecuted: '待执行',
    homeShelve: '暂搁置',
    homeReviewed: '待审核',

    chartTitle: '图表',
    homeRcTaskTit: '巡检单执行情况',
    homeWoTaskTit: '工单执行情况',
    homePeIrTit: '事件报告执行情况',
    homeAbLocTit: '异常巡检点数量',
    homeHrRcTit: '人员巡检情况',
    noData: '暂无数据',
    meter: '仪表',
    fold: '收起',
    unfold: '展开',
    switchChartTip: '切换图表',
    startDate: '开始时间',
    endDate: '结束时间',

    just: '刚刚',
    yearsAgo: '年前',
    monthsAgo: '月前',
    weeksAgo: '周前',
    daysAgo: '天前',
    hoursAgo: '小时前',
    minutesAgo: '分钟前',

    yearAgo: '年前',
    monthAgo: '月前',
    weekAgo: '周前',
    dayAgo: '天前',
    hourAgo: '小时前',
    minuteAgo: '分钟前',

    nameAxis: '名称',
    amountAxis: '数量',
    percentageAxis: '百分比',

    viewTutorial: '查看课程',
    tutorialStep: '课程{num}',
    functionStep: '功能{num}',
    tutorialIsEmpty: '暂无课程',
    tutorialTitle: '学习基础课程',
    tutorialSubtitle: '仅需 {num} 步让您轻松掌握巡逻巡检管理秘诀',
    tutorialOtherTitle: '其他功能',
    tutorialOtherSubtitle: '探索更多实用功能',
    tutorialPopTips: '系统检测到您的账号尚处于新手摸索期，所以为了确保您能了解核心功能，本引导将会每次登陆后自动出现，直至你们成功完成10次巡检。',
    alwaysAppear: '我为什么总会出现？',
  },
  homeConfigDashboard: {
    configDashboard: '设置',
    meterConfig: '仪表配置',
    addMeter: '新增仪表',
    meters: '仪表',
    meterSearch: '请输入监控模板名称',
    refreshRate: '刷新率：{number} 分钟',
    noRefresh: '数值为0，不刷新',
    chartConfig: '图表配置',
    chartDisplayTime: '图表显示时间',
    nearlySevenDays: '近7天',
    nearlyThirtyDays: '近30天',
    nearlyNinetyDays: '近90天',
    thisMonth: '这个月',
    lastMonth: '上个月',
    thisQuarter: '这个季度',
    lastQuarter: '上个季度',
    thisYear: '今年',
    prevYear: '去年',
  },
  homeAccountDetail: {
    peDesc: '物业实体名称',
    accountId: '登录账号',
    accountName: '名称',
    accountRole: '角色',
    authGroup: '用户权限组',
    task: '任务',
    intervalToCreateTempTask: '每 {content} 产生巡检单',
    tempTask: '临时任务',
    nextInstime: '下次执行巡检时间',
    notSet: '未设置',
    unsPec: '未指定',
    status: '状态',
    suswo: '持续性工单',
  },
  propertyList: {
    proEntity: '物业实体',
    proEntityTips: '物业实体是供团队管理的工作地点的定义，巡逻路线、工单、统计资讯都在物业实体中定义。允许您分离多网站团队的管理。',
    searchOfficeName: '搜索物业实体名称或物业实体简称',
    current: '当前',
    switchProperty: '切换到此物业实体',
    numberLimit: '已达当前版本所支持的最大可创建数量，如需增加最大可创建数量，请升级版本，如有疑问，请联系客服。',
    emptyTips: '未找到相关物业实体。',
    nameAsc: '按名称升序',
    nameDesc: '按名称降序',
    createdTimeAsc: '按创建时间升序',
    createdTimeDesc: '按创建时间降序',
    basinfo: '基本信息',
    homeAccount: '使用账号',
    homeLoc: '巡检点',
    comitemman: '常用项管理',
    instask: '巡检计划',
    inslist: '巡检单',
    increport: '事件报告',
    publishNotice: '通知管理',
  },
  propertyCreate: {
    createPe: '创建物业实体',
    imageUploadShowTip: '建议尺寸为304px*200px，最大不超过{size}',
    peabAccountTips: '您登录应填写的物业实体简称为: ',
    loginMustInput: '登录时需填写(包含后缀)，物业实体唯一识别标识，建议使用英文数字填写',
    alertPhoneTips: '巡检人员紧急联系电话',
    correctPeName: '请输入正确的物业实体名称',
    peNameLengthTips: '物业实体名称不能超过200字',
    peNameEmptyTips: '请输入物业实体简称',
    peNameFormat: '4~20个字符，仅可使用字母、数字',
    correctalTerphone: '请输入正确的电话号码',
    officialPropertyNameTip: '您的正式物业实体简称为: ',
    entityName: '物业实体名称',
    abbreviation: '物业实体简称',
    industry: '物业类别',
    address: '所在地址',
    addressLengthTips: '所在地址不能超过200字',
    emergencyContact: '紧急联系',
    photoWatermarked: 'APP巡检点照片带水印',
    uploadPhoto: '上传照片',
  },
  propertyDetail: {
    basinfo: '基本信息',
    imageUploadShowTip: '建议尺寸为304px*200px，最大不超过{size}',
    peabAccountTips: '您登录应填写的物业实体简称为: ',
    editSuccess: '实体详细信息已保存!',
    officialPropertyNameTip: '您的正式物业实体简称为: ',
    entityName: '物业实体名称',
    abbreviation: '物业实体简称',
    industry: '物业类别',
    address: '所在地址',
    emergencyContact: '紧急联系',
    photoWatermarked: 'APP巡检点照片带水印',
    uploadPhoto: '上传照片',
  },
  account: {
    mainAccount: '用户账户',
    mainAccountTips: '所有313FM使用者都需要分配一个用户账户才能登录，所有313任务和访问许可权将被授予使用者，或多个使用者组成的访问组。可以根据个人，工作角色或班次创建用户账户。',
    accountId: '登录账号',
    accountName: '名称',
    accountRole: '角色',
    authGroup: '用户权限组',
    dataAdmin: '数据管理员',
    pemgr: '物业实体经理',
    generalUser: '一般使用者',
    confirmDelete: '请确认是否删除该记录？',
    accountQueryTips: '请输入登录账号或名称',
    emptyTips: '未找到相关用户账户。',
    deleteSuccess: '登录账号已成功删除！',
    accountDeleted: '该账号已删除',
  },
  accountCreate: {
    newAccount: '创建登录账号',
    editAccount: '编辑登录账号',
    loginMustInputAcc: '登录时需填写，账号的唯一标识，建议使用英文数字填写',
    accountIdEmptyTips: '请输入登录账号',
    accountNameEmptyTips: '请输入名称',
    accountIdOver: '登录账号不能超过20字',
    accountNameOver: '名称不能超过20字',
    useAccountPhoneErrmsg: '只可输入数字',
    checkPasswordeError: '两次输入密码不一致',
    useAccountPhoneTips: '用于短信提醒，默认使用注册手机账号的区号，可以变更',
    thirdpartyAccount: '用于单点登录的 Azure AD 企业用户账户',
    accountPassMsg: '留空默认为123456',
    accountPassMsg2: '留空不修改密码',
    dataAdmin: '数据管理员',
    pemgr: '物业实体经理',
    dataManTips: "仅'数据管理员'具有维护基础数据的权限。",
    peManTips: "物业实体经理可进行电子标签数据绑定。",
    accountId: '登录账号',
    accountName: '名称',
    selPhoneNum: '手机号码',
    email: '第三方账号',
    accountPass: '密码',
    checkPassword: '确认密码',
    checkPasswordPh: '重复相同的密码',
    createSuccess: '登录账号已成功添加！',
    editSuccess: '登录账号已成功修改！',
    numberLimit: '已达当前版本所支持的最大可创建数量，如需增加最大可创建数量，请升级版本，如有疑问，请联系客服。',
  },
  authManagement: {
    authorizedPersonnel: '授权管理员',
    manageacl: '授权管理',
    authTips: '如果您需要将物业实体授权给他人管理，可点击“添加授权人员”，该授权人员使用手机号登录，权限等同于物业实体“数据管理员”，除不允许操作本页面信息外，允许操作该实体下的其他所有数据。',
    authManPhone: '授权管理员手机号',
    authName: '名称',
    remark: '备注',
    confirmDelete: '请确认是否删除该记录？',
    emptyTips: '未找到相关授权管理员。',
    deleteSuccess: '授权管理员已成功删除！',
  },
  authManagementCreate: {
    addAuthMenber: '添加授权人员',
    editAuthMenber: '编辑授权人员',
    authPhone: '授权手机号',
    remark: '备注',
    authPhoneTips: '如果添加的手机号尚未注册，待该手机注册后，物业实体会与其自动关联。',
    correctPhoneNum: '请输入正确的手机号',
    phoneEmptyTips: '请输入手机号',
    nameWordsLimit: '名称不能超过25字',
    createSuccess: '授权人员已成功添加！',
    editSuccess: '授权人员已成功修改！',
  },
  authority: {
    manageacl: '用户组权限',
    manageAclDescription: '当前系统权限仅可以设定针对于报表使用及展现的数据权限设定。',
    aclName: '用户权限组名称',
    description: '描述',
    deleteAuthGroup: '请确认是否删除该权限组？',
    deleteSelectedBtn: '删除已选择 ({count})',
    emptyTips: '未找到相关用户权限组。',
    search: '请输入用户权限组名称或描述',
    deleteSuccess: '用户组权限已成功删除！',
  },
  authorityCreate: {
    addAuth: '新增用户权限组',
    editAuth: '编辑用户权限组',
    aclName: '用户权限组名称',
    description: '描述',
    aclNameEmptyTips: '请输入用户权限组名称',
    accountNameOver: '名称不能超过20字',
    data: '数据',
    dataItemDescription: '只能查看同权限组下的数据',
    setReportButton: '设置报表权限',
    function: '功能',
    createSuccess: '用户权限组已成功添加！',
    editSuccess: '用户权限组已成功修改！',
  },
  authoritySetReport: {
    setReportAuth: '设置报表权限',
    enterSearch: '请输入搜索内容',
    rptName: '报表名称',
    authorized: '权限',
    setSuccess: '设置报表权限成功！',
  },
  authorityMember: {
    groupMenber: '组成员',
    accountId: '登录账号',
    accountName: '名称',
    accountRole: '角色',
    search: '请输入登录账号或者名称',
    deleteSuccess: '组成员已成功删除！',
    createSuccess: '组成员已成功添加！',
  },
  group: {
    adminGroup: '工作组',
    adminGroupTips: '创建巡检计划、工单指定执行人的时候，除了可以选一个使用账号，也可以选一个工作组作为执行人',
    groupName: '组名称',
    groupDescription: '组描述',
    memberNum: '成员数量',
    memberNumIs0: '此工作组内没有成员，请给此工作组添加组成员或者重新选择工作组',
    enterSearch: '输入组名称或组描述',
    createGroup: '创建工作组',
    editGroup: '编辑工作组',
    emptyTips: '未找到相关工作组。',
    deleteSuccess: '工作组已成功删除！',
    deleteGroup: '请确认是否删除该工作组？',
    groupDeleted: '该工作组已删除',
  },
  groupCreate: {
    groupName: '组名称',
    groupDescription: '组描述',
    groupNameEmptyTips: '请输入组名称',
    groupNameOver: '名称不能超过50字',
    createSuccess: '工作组已成功添加！',
    editSuccess: '工作组已成功修改！',
  },
  groupMember: {
    groupMember: '组成员',
    accountId: '登录账号',
    accountName: '名称',
    accountRole: '角色',
    search: '请输入登录账号或名称',
    deleteSuccess: '组成员已成功删除！',
    createSuccess: '组成员已成功添加！',
    selectMemberEmpty: '至少选择一个账号',
  },
  groupMemberCreate: {
    addGroupMenber: '增加组成员',
    search: '请输入登录账号或名称',
  },
  location: {
    mainPatPoint: '巡检点',
    mainPatPointTips: '巡逻点是与之关联的所有313FM任务的物理位置的定义。通常，巡逻点被分配到关键位置，供员工登录或检查关键资产，例如正门，后门，洗手间，变压器室，MCP等。定义巡逻点后，您可以使用它创建巡逻路线或工作订单。',
    patPoint: '巡检点名称',
    patPointName: '巡检点名称',
    patPointNum: '巡检点编号',
    patPointType: '标识类型',
    needUseLabel: '需要扫描开启',
    isEffective: '有效？',
    noLocal: '暂无可删除巡检点',
    allLocDeleteTips: '确定要删除所有巡检点吗？',
    importPointer: `成功导入 {count} 行数据`,
    pleaseSelectExcel: '请选择Excel文件',
    selectDoc: '选择文件',
    selectFileTips: '请选择文件',
    pleaseSelectExcelUpload: '请上传Excel模板文件：',
    confirmDelete: '请确认是否删除该记录？',
    enterSearch: '请输入巡检点编号或名称',
    selectPatPoint: '选择巡检点',
    clearAll: '清除全部',
    selectPointTit: '使用中的巡检点',
    selectedLength: '已选择 {count}',
    importPatPointExecl: '导入巡检点',
    importExcelInfo: '请根据导入模板准备数据。文件较大时，导入数据的时间可能较长，请耐心等待。',
    downloadTemplate: '下载模板',
    createPatPoint: '创建巡检点',
    editPatPoint: '编辑巡检点',
    emptyTips: '未找到相关巡检点',
    deleteSuccess: '巡检点已成功删除！',
    bluetooth: '蓝牙',
    qrcode: '二维码',
    nfc: 'NFC',
    meters: '米',
    detail: '详细',
    initMapFailed: '地图初始化失败！',
    initGoogleMapFailed: 'Google地图加载失败，建议在账号信息上切换使用百度地图。',
    limitTips: '当前帐号总巡检点数量为：{num}，如需增加巡检点数量，或扩容，请联系平台客服',
    addUnknownPoint: '添加未知巡检点',
    changeUnknownPoint: '更换为未知巡检点',
  },
  locationCreate: {
    patPointNameTips: '巡检点的名称，用于定义要检查的物理位置或资产',
    pointNameEmptyTips: '请输入巡检点名称',
    locLimit100Words: '巡检点名称不能超过100字',
    locNumLimit20Words: '巡检点编号不能超过20字',
    patPointNumTips: '巡检点编号',
    patPointTypeTips: '巡检点的类型，可以是NFC，QR码（二维码），蓝牙或GPS',
    isUseLabel: '巡检单需要扫描标签方可开启巡检',
    isUseLabelTips: '启用后，强制检查员在到达时扫描巡逻点以表示在巡逻点现场',
    isEffectiveTips: '一旦无效以后，新生成的巡检单与工单中将不包含该巡检点',
    lng: '经度',
    lat: '纬度',
    enterSearch: '请输入搜索内容',
    mapPoint: '巡点位置',
    patPointName: '巡检点名称',
    patPointNum: '巡检点编号',
    patPointType: '标识类型',
    errorRange: '误差范围',
    isEffective: '有效？',
    createSuccess: '巡检点已成功添加！',
    editSuccess: '巡检点已成功修改！',
  },
  holiday: {
    holidayManage: '假期管理',
    holidayTips: '设定假期或非工作日的时间范围，可跨时间段（如2月4日至2月7日中国农历春节）设定，为满足早晚交班需要，定义时需指定相应开始/结束日期。在设定的时间范围内，满足日检/小时检（但不包括周/月/年检）的巡检计划，将视为非工作日不生成巡检单。',
    holidayStart: '假期开始日期',
    holidayEnd: '假期结束日期',
    holidayRemark: '假期备注',
    importPointer: `成功导入 {count} 行数据`,
    pleaseSelectExcel: '请选择Excel文件',
    selectDoc: '选择文件',
    selectFileTips: '请选择文件',
    pleaseSelectExcelUpload: '请上传Excel模板文件：',
    copyHoliday: '复制到其他物业实体',
    noperMission: '暂无权限',
    pleaseCheckHoliday: '请选择要复制的假期',
    deleteSuccess: '假期已成功删除！',
    enterSearch: '请输入假期备注',
    importHoliday: '导入假期',
    emptyTips: '未找到相关假期。',
  },
  holidayCreate: {
    createTitle: '新增假期',
    editTitle: '编辑假期',
    holidayStartTips: '在开始/结束的时间范围内，日检/小时检的巡检单将不生成，且系统不对重复的假期设定做限制，请确认设置无误。',
    holidayStart: '假期开始日期',
    holidayEnd: '假期结束日期',
    noWorkdayRemark: '备注',
    createSuccess: '假期已成功添加！',
    editSuccess: '假期已成功修改！',
    holidayLimitWords: '假期备注不能超过25字',
    requiredStartdate: '请选择开始日期',
    requiredEnddate: '请选择结束日期',
  },
  holidayCopy: {
    pleaseCheckPenum: '请选择要复制的物业实体',
    copySuccess: '假期已成功复制！',
    propertyEntity: '物业实体',
    enterSearch: '请输入搜索内容',
    selected: '已选择 {count}',
  },
  assets: {
    assetAsccount: '资产台账',
    assetLedgeRexPlain: '这是创建和定义资产的位置，可以将照片附加到资产上以便于识别。 定义资产后，可以使用它创建工单订单。',
    enterSearch: '请输入资产名称或编号',
    assetsName: '资产名称',
    assetsId: '资产编号',
    location: '所在位置',
    status: '状态',
    assetDelTips: `该资产已经在 "{text}" 等 {num} 个工单模板中使用，删除后将导致生成的工单模板显示异常，请确认是否删除？ `,
    confirmDelete: '请确认是否删除该记录？',
    deleteSuccess: '资产已成功删除！',
    assetStatusAll: '全部',
    assetStatusSelect: '请选择状态',
    switchAsset: '选择资产',
    emptyTips: '未找到相关资产。',
  },
  assetsCreate: {
    createTitle: '创建资产台账',
    editTitle: '编辑资产台账',
    viewTitle: '资产台账详情',
    assetsName: '资产名称',
    assetBrand: '品牌',
    assetModel: '型号',
    assetManufactureDate: '生产日期',
    assetInstallDate: '安装日期',
    assetSerialNo: '机身编号',
    assetStatutory: '法定设备',
    assetStatutoryTips: '法定资产是受政府监管和要求约束的资产',
    location: '所在位置',
    assetDesc: '详细内容',
    pleaseSelectDate: '选择日期',
    assetPicTypeTips: '只能上传 .jpg、.jpeg、.png 格式的文件',
    assetAlert: '资产名称不能为空',
    assetAlertExceed: '资产名称不能超过50字',
    assetLocationTips: '所在位置不能为空',
    assetAlertDesLength: '详细内容不能超过1000字',
    createSuccess: '资产已成功创建！',
    editSuccess: '资产已成功编辑！',
    status: '状态',
  },
  assetsLocationSelect: {
    selectLocation: '选择位置',
    enterSearch: '请输入巡检点编号或者名称',
  },
  sensor: {
    sensor: '物联网传感器',
    sensorName: '物联网传感器名称',
    sensorDesc: '描述',
    sensorNum: '物联网传感器编号',
    sensorCode: '物联网传感器编码',
    sensorDelTips: `该传感器已经在 "{text}" 等 {num} 个监控模板中使用，删除后将导致生成的监控模板显示异常，请确认是否删除？ `,
    confirmDelete: '请确认是否删除该记录？',
    sensorExplain: '物联网传感器设置完成后，可以通过在此处使用传感器代码创建传感器，将传感器关联到 313 平台。 然后可以将 IoT 数据与此传感器定义相关联。 可以在这里定义传感器内部的具体仪表，包括单位、读数类型、下限和上限。',
    enterSearch: '请输入物联网传感器名称、描述或编码',
    deleteSuccess: '物联网传感器已成功删除！',
    emptyTips: '未找到相关物联网传感器。',
  },
  sensorCreate: {
    createTitle: '创建物联网传感器',
    editTitle: '编辑物联网传感器',
    viewTitle: '物联网传感器详情',
    sensorName: '物联网传感器名称',
    sensorCode: '物联网传感器编码',
    sensorDesc: '描述',
    sensorPic: '照片',
    sensorNameEmptyTips: '物联网传感器名称不能为空',
    sensorNameExceedTips: '物联网传感器名称不能超过50字',
    sensorCodeEmptyTips: '物联网传感器编码不能为空',
    sensorCodeExceedTips: '物联网传感器编码不能超过200字',
    createSuccess: '物联网传感器已成功创建！',
    editSuccess: '物联网传感器已成功编辑！',
    meter: '仪表',
    createMeterTitle: '添加仪表',
    editMeterTitle: '编辑仪表',
    meterType: '仪表类型',
    meterUnit: '仪表计量单位',
    meterReading: '仪表读数类型',
    sensorkey: '物联网传感器密钥',
    sensorLowerLimit: '下限',
    sensorLowerLimitTips: '请输入下限',
    sensorUpperLimit: '上限',
    sensorUpperLimitTips: '请输入上限',
    numberTips: '请输入数值，至多保留两位小数',
    meterLowerTips: '首页仪表配置展示最小量程',
    meterUpperTips: '首页仪表配置展示最大量程',
    meterSelect: '请选择',
    meterPleaseSelect: '请选择仪表',
    meterTypeEmptyTips: '仪表类型不能为空',
    meterUnitEmptyTips: '仪表计量单位不能为空',
    meterUnitExceedTips: '仪表计量单位不能超过50字',
    meterReadingEmptyTips: '仪表读数类型不能为空',
    limitLessThanTips: '下限只能小于或等于上限',
    limitGreatEroreQualHanTips: '上限只能大于或等于下限',
    meterAddTips: '请添加仪表',
  },
  userinfo: {
    accountDetailTitle: '账号信息',
    change: '修改',
    configPhone: '手机号',
    account: '账号',
    configUserName: '名称',
    email: '第三方账号',
    timeZone: '时区',
    onlyKeep: '只保留',
    daystoinsattachment: '天内的巡检单附件',
    mapSelect: '选择地图',
    allowTask: '允许APP跨物业实体接受任务',
    dontShowContactBar: '不显示底部联系栏',
    dontShowConsultation: '不显示在线咨询',
    phoneMsgTips: '短信或语音提醒',
    password: '密码',
    smsCountContent: '短信使用量：{num} / {total}（短信总数）',
    voiceCountContent: '语音包使用量：{num} / {total}（语音包总数）',
    userLogo: '上传自定义LOGO',
    qrLogo: '上传二维码LOGO',
    userLogoInfo: '点击上传账号自定义logo，建议尺寸为280px*200px，最大不超过2MB',
    qrLogoInfo: '点击上传自定义二维码logo，建议尺寸为200px*200px，最大不超过2MB',
    editSuccess: '修改成功',
  },
  userinfoSsoConfig: {
    microsoftConfiguration: 'OAuth2配置',
    tenantId: '租户ID',
    clientId: '客户端ID',
    secret1: '密钥',
    secret1Date: '密钥截止期限',
    description: '描述',
    saveSuccess: '保存成功',
  },
  userinfoEditPhone: {
    editPhoneTitle: '修改手机号',
    configPhone: '手机号',
    moreAreaCode: '更多区号',
    oldPassword: '原密码',
    newPass: '新密码',
    confirmNewPassword: '确认新密码',
    confirmNewPasswordPh: '再次输入新密码',
    verCode: '验证码',
    phoneCode: '手机验证码',
    correctPhoneNum: '请输入正确的手机号',
    configNewPassFormat: '请输入6-20位数字、字母或特殊符号，特殊符号仅支持@#&*_',
    repassTips: '请重复输入相同的密码',
    correctImageCode: '请输入正确的图形验证码',
    correctCode: '验证码错误，请重新输入或再次发送',
    getcodeBtn: '获取验证码',
    secgetcodeBtn: '{second} 秒',
    phoneSendSuccess: '验证码发送成功',
    editPhoneSuccess: '手机号码修改成功！',
  },
  userinfoEditPass: {
    editPassTitle: '修改密码',
    configNewPass: '新密码',
    checkPassWord: '确认密码',
    editPassSuccess: '密码修改成功！',
  },
  userinfoViewSms: {
    smsInfoDetailTitle: '短信发送详细',
    enterSearch: '请输入搜索内容',
    detailTitle: '详细',
    smsPeDesc: '所在物业',
    smsContent: '短信内容',
    smsSendTime: '发送时间',
    smsIsVoiceLabel: '语音提醒',
    status: '状态',
    success: '成功',
    failure: '失败',
    startDate: '开始时间',
    endDate: '结束时间',
    to: '到',
    smsErrorMsgLabel: '错误信息',
    smsSendName: '接收者',
    smsSendPhone: '发送号码',
    emptyTips: '未找到相关短信内容。',
  },
  notice: {
    notice: '通知管理',
    noticeTips: '可向物业实体内的相关用户发布信息通告，通知会推送至手机APP',
    noticeTitle: '标题',
    noticeOutline: '概要',
    publishName: '发布人',
    publishTime: '发布时间',
    deleteSuccess: '通知删除成功！',
    noticeQueryTips: '请输入通知标题或概要',
    emptyTips: '未找到相关通知。',
  },
  noticeRead: {
    noticeReadTitle: '已读',
    notifyperName: '被通知人员的名称',
    isRead: '已读',
    noticeReadTime: '读取通知的时间',
  },
  noticePublish: {
    publishNotice: '发布通知',
    noticeTips: '可向物业实体内的相关用户发布信息通告，通知会推送至手机APP',
    noticeTitlePlaceholder: '请输入标题',
    noticeTitleTips: '请输入标题',
    noticeContentPlaceholder: '请输入内容',
    noticeContentTips: '请输入内容',
    noticeTitleOver: '标题不能超过50字',
    noticeContentOver: '内容不能超过300字',
    setNoticeRange: '设置通知范围',
    notifyAll: '通知所有人',
    checkSelNoticeper: '选择通知的人员！',
    publishSuccess: '新的通知发布成功！',
  },
  noticePublishRange: {
    dialogNotifyAllTitle: '选择通知的人员',
    UserList: '用户列表',
    clearAll: '清空',
    selected: '已选择 {count}',
  },
  selectTaskTemplate: {
    enterRsearch: '请输入搜索内容',
    instask: '巡检计划',
    executor: '执行人',
  },
  task: {
    mainInstask: '巡检计划管理',
    mainInstaskTip: '定义巡检计划的具体内容，包括巡检哪些巡检点，每个巡检点有哪些巡查项，以及巡检计划的具体执行计划，由谁完成等。',
    name: '计划名称',
    inspPlan: '巡检计划',
    toCreateTempTask: '每 {interval} {frequnit} 产生巡检单',
    tempTask: '临时任务',
    onTimeCompletion: '{count} 按时完成',
    overdueCompletion: '{count} 超时完成',
    cancelled: '{count} 已取消',
    notFollowUp: '{count} 未处理',
    checkStartTask: '“下次计划巡检时间”或“巡检执行人”未设置，无法启动巡检计划',
    startTask: '巡检计划启动成功！',
    stopTask: '巡检计划停止成功！',
    nextInsTime: '下次执行巡检时间',
    assignee: '执行人',
    insman: '巡检执行人',
    reviewer: '复核人',
    insTaskQueryTips: '请输入巡检计划名称或任务编号',
    deleteSuccess: '巡检计划删除成功！',
    emptyTips: '未找到相关巡检计划。',
  },
  taskCreate: {
    createPatTask: '创建巡检计划',
    editPatTask: '编辑巡检计划',
    taskName: '计划名称',
    pleaseInputRctaskName: '巡检计划名称不能为空',
    inputRctaskNameLengthTips: '巡检计划名称不能超过100字',
    checkSelinsExist: '巡检执行人不能为空',
    pleaseRcwoDate: '巡检日期时间不能为空',
    pleaseInterval: '巡检计划间隔不能为空',
    nextScheduledTimeTips: '计划下次执行巡检时间不能为空',
    correctIntervalTips: '请输入正确的时间间隔',
    patPoinAndPatitem: '巡检点及巡查项',
    patPlan: '巡检计划',
    addTaskNameTips: '一定时间周期内反复执行的计划名称，比如可以用以下名称作为巡检计划名称：安保巡逻路线A，设备定期保养，电梯年检等',
    setExecutor: '安排巡检执行人',
    selectExecutor: '选择人',
    selectGroup: '选择组',
    perint: '每',
    togenPatList: '生成巡检单',
    intervalTips: '（系统将会在第1，第{nexttime}{frequnit}生成巡检单）',
    nextInsPlanTime: '计划下次执行巡检时间为',
    reviseInsPlanTime: '重新修改巡检时间',
    to: '至',
    groupList: '巡检组列表',
    executorList: '巡检执行人列表',
    reviewerList: '巡检复核人列表',
    selectReviewerTips: '请选择巡检复核人',
    effTimeRange: '生效时间范围',
    validPeriod: '此巡检计划在',
    validPeriodLast: '之后失效',
    validPeriodTips: '巡检计划将在到期日期时间后自动失效',
    weeklySet: '每周设定',
    mon: '星期一',
    tue: '星期二',
    wed: '星期三',
    thu: '星期四',
    fri: '星期五',
    sat: '星期六',
    sun: '星期日',
    other: '其他',
    holiday: '假期',
    monthSeting: '月份设定',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sept: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    holidayTips: '如有其他假日或非工作日无需生成巡检单，可点击左侧菜单中“假期管理”进行设定',
    checkSelectExecutor: '请选择巡检执行人',
    checkSelectGroup: '请选择巡检执行组',
    efftimeRangeInfoOne: '用来定义巡逻/巡检的时间范围，例如某企业的设备泵房必须在上午8点到下午5点之间进行每隔1小时的巡查，则生效时间范围为：08:00 至17:00. 系统会在生效时间范围内生成巡检单，也可以提早生成。',
    efftimeRangeInfoTwo: '如果“结束时间”早于“开始时间”，则代表时间范围为次日此时间点结束。',
    efftimeRangeInfoThree: '不填写则代表24小时生效。',
    efftimeRangeInfoFour: '这个设定只有在频率为小时的时候才有效，其他频率则可留空。',
    inspectionPlanOptions: '巡检计划选项',
    autoCreateTask: '开启无计划巡检模式',
    isNeedAdvance: '需要提前生成巡检单',
    autoCreateTaskDescRiption: '勾选后，此巡检计划不再根据设定的巡检时间和频率生成。只要当前巡检单完成后，立即自动生成下一张巡检单。',
    isNeedAdvanceInfo: '例如巡检计划的计划执行时间为上午10:00，你可以设定巡检单提前1小时生成，则每天上午9:00，相应的巡检单会派送到巡检执行人的手机APP任务箱。',
    needAdvanceHourTocReateins: '需提前 {content} 生成巡检单',
    openCondition: '应用巡检计划开启条件',
    openConditionTips: '可从APP的“设置”中的“巡检设定”来控制巡检计划开启条件，未满足开启条件的巡检单将被删除且停止生成，满足开启条件的巡检单将被生成，未设定开启条件的巡检单不受影响。',
    pause: '暂停',
    openStart: '启用',
    multiPersonInspection: '允许多人协作巡检',
    multiPersonInspectionTips: '如果勾选，则此巡检单可由组内人员分别执行不同巡检点，且不可转发。',
    multiPersonInspectionCheckedTips: '勾选 “允许多人协作巡检” 后，“巡检路线必须按巡点次序” 将会失效',
    inspectionOptions: '巡检选项',
    autoCompUponScan: '扫描即完成 / 巡逻棒模式',
    autoCompUponScanInfo: '检查时，如果没有附加检查表，此巡检点将自动完成。这将方便于需要连续扫描的情况。如果未选中，则用户必须单击“完成”按钮才能转到下一个检查点。',
    needRoute: '巡检路线必须按巡点次序',
    needRouteInfo: '如勾选，则需要按照巡检点的序号来依次完成巡检。',
    needPhoto: '强制上传照片',
    needPhotoInfoOption: '如果勾选，则此巡检路线上所有巡点必须强制上传照片方为完成',
    scanAgain: '完成时需要再次扫描',
    scanAgainInfo: '如果勾选，则每个巡检点完成工作后需要再次扫描标签',
    // scanAgainInfo: '如果勾选，则此巡检路线上所有巡点必须强制完成时需要再次扫描。',
    noCancel: '不允许取消',
    noCancelInfo: '如果勾选，则此巡检单不可强行取消。',
    signinAdvance: '需要提前签名',
    signMethod: '签名方式',
    handlewrite: '手写签名',
    signPic: '签名拍照',
    managementOptions: '管理选项',
    isNeedReviewer: '需要巡检复核人',
    setReviewerInfo: '如果需要，巡检单操作完成后，流程会提交至指定的物业实体经理进行复核。',
    promanagerNotificationOptions: '提醒选项',

    addPatPoint: '添加巡检点',
    addPatPointTips: '巡检计划是一个需定期执行的任务，而每次执行时，需巡逻的地方，以及巡逻的内容则需通过此按钮添加',

    taskRemind: '巡检单未执行提醒',
    taskRemindTips: '勾上以后可设定超过巡检开始计划时间多少时间没响应，即发送APP提醒消息给执行人。可设定超过巡检开始计划时间多少时间的单位为分钟。如该执行人在指定时间内已有签到数据的话，认为已开始巡检计划，无需提醒，反之发送提醒消息。',
    needPhotoInfoPart: '如果勾选，则巡逻至此巡点时候必须上传照片方为完成',
    makeWarning: '未按时完成巡检单通知',
    makeWarningTips: '仅在线巡检模式有效，超过指定时间未签到时警报',
    recordOnly: '记录',
    mustBeMinutes: '应在 {content} 分钟内签到',
    earlyMinToConsider: '提早 {earlyContent} 分钟或者延误 {lateContent} 分钟为不正常, 执行',
    seNum: '序号',
    patContent: '巡查内容',
    feedBackVal: '反馈可选项',
    needFeed: '必须反馈？',
    colData: '采集数据？',
    warningCheckNeedRoute: '勾选 "巡检选项" - "巡检路线必须按巡点次序" 后才生效',
    taskRemindDesc: '巡检执行人需在巡检开始后 {content} 分钟内响应巡检单任务，否则将发送提醒执行人。',
    justOneReminder: '只提醒一次',
    remindLimitTip: '最多提醒10次',

    respondIsIntervalLabel: '每隔 {content} 分钟提醒一次，直至应该完成时间或响应时间到达为止',
    remindRecipientLabel: '设置提醒接收人',
    executor: '执行人',
    peManager: '物业经理',
    remindOther: '其他',

    remindPushMethodLabel: '提醒方式',
    remindPushApp: 'App推送',
    remindPushSms: '短信提醒',
    remindPushVoice: '语音提醒',
    taskRemindRequired: '巡检单未执行提醒是必需的',
    reviewerRequired: '巡检复核人是必填字段',
    respondIntervalRequired: '时间间隔是必需的',
    leadtimeRequired: '提前生成巡检单时间是必需的',
    outtimeTimelimitRequired: '完成时间是必需的',
    pointTimeoutTips: '巡检点到达延误通知',
    locDelayLabelTip: '需要在巡检点上设置到达时间，并勾选必须按顺序巡检选项',
    firstPolling: '以第一个巡检点为准',
    prevPolling: '以前一个巡检点为准',
    remindIsAlertLabel: '记录到警报历史',
    noSupTips: '当前版本不支持此功能！',
    isSaveWithoutLoc: '当前任务没有添加巡检点，是否继续创建？',

    insneedMinuteToComp: '巡检需在 {content} 分钟内完成，超出即为超时完成，将发送超时提醒',
    overTimeIsAbnormal: '超出 {content} 分钟时为不正常',
    taskRemindToFinish: '完成提醒',
    taskRemindToFinishTips: '开启以后，一旦巡检单/工单执行完成后，发送消息提醒给物业实体经理角色。',
    remindExecuteLabel: '巡检单执行提醒',
    remindExecuteLabelTip: '巡检单转发、复核、退回将发送提醒给执行人',
    trackingOptions: '跟踪选项',
    calsignTime: '计算签到时间',
    mileage: '里程数 {content} 公里',
    mileageTips: '里程数不能小于0',
    mileageInfo: '此巡检路线的总里程长度，一般为所有巡点之间的距离总和',
    trarecfre: '轨迹回收频率 {content} 分钟',
    trarecfreTips: '轨迹回收频率不能小于0',
    cancelCheckNeedRouteTips: '取消勾选巡检路线必须按巡点次序后，巡检点到达延误通知 和 未按时完成巡检单通知将会失效',
    checkDelayTips: '勾选巡检点到达延误通知后，需勾选 "巡检路线必须按巡点次序" 才能生效',
    checkOuttimeTips: '勾选未按时完成巡检单通知后，需勾选 "巡检路线必须按巡点次序" 才能生效',
    addPatItem: '添加巡查项',
    selectComItem: '从常用项选择',
    selectSysItem: '从系统项选择',
    copyPatItem: '从其他巡检点复制巡查项',
    patPointName: '巡检点名称',

    taskExample: '巡检单生成结果示例',
    taskExampleTipsTitle: '示例生成说明：',
    taskExampleTipsHours: '频率选择小时/天/周：生成2个月示例',
    taskExampleTipsMonth: '月：生成1年示例',
    taskExampleTipsYear: '年：生成10年示例',

    taskUse: '启用',
    taskGenerate: '生成巡检单',

    remindMethodSmsCheckedTip1: '需勾选 "账号信息 - 短信或语音提醒" 才能生效',
    remindMethodSmsCheckedTip2: '短信剩余数量为 0',
    remindMethodVoiceCheckedTip: '语音剩余数量为 0',
    locChange: '更换',
    top: '置顶',
    bottom: '置底',
    moveUp: '向上排序',
    moveDown: '向下排序',
    expand: '展开',
    collapse: '收起',
  },
  taskDetail: {
    viewInstaskDetail: '查看巡检计划',
    copyToNewTask: '复制成一个新的巡检计划',
    cmpontime: '按时完成',
    cmpovrdue: '超时完成',
    notfollow: '未处理',
    cancelled: '已取消',
    taskNum: '任务编号',
    taskName: '计划名称',
    perint: '每',
    togenPatList: '生成巡检单',
    intervalTips: '（系统将会在第1，第{nexttime}{frequnit}生成巡检单）',
    nextInsplanTime: '计划下次执行巡检时间为',
    effTimeRange: '生效时间范围',
    notset: '未设置',
    to: '至',
    nextDay: '次日',
    insman: '巡检执行人',
    notpoint: '未指派',
    reviewer: '复核人',
    historyTotal: '历史总计',
    trarecfre: '轨迹回收频率',
    modinsExecutor: '修改巡检执行人',
    selectGroup: '选择组',
    selectExecutor: '选择人',
    weeklyEfftime: '每周生效时间',
    monthSeting: '月份设定',
    needAdvance: '需提前',
    toCompover: '内完成，超出即为超时完成',
    insNeed: '巡检需在',
    mileage: '里程数',
    kilo: '公里',
    mon: '星期一',
    tue: '星期二',
    wed: '星期三',
    thu: '星期四',
    fri: '星期五',
    sat: '星期六',
    sun: '星期日',
    other: '其他',
    holiday: '假期',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sept: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    effect: '生效',
    recentFive: '最近5次巡检情况',
    patNum: '巡查单号',
    insplanTime: '计划巡检时间',
    actComTime: '实际完成时间',
    insmanName: '巡检人姓名',
    status: '状态',
    foleventrep: '后续事件报告',
    currencyLoc: '未知巡检点',
    deleteLocation: '该巡检点已删除',
    homeLoc: '巡检点位置',
    seNum: '序号',
    patContent: '巡查内容',
    feedBackVal: '反馈可选项',
    needFeed: '必须反馈？',
    colData: '采集数据？',
    checkStartTask: '“下次计划巡检时间”或“巡检执行人”未设置，无法启动巡检计划',
  },
  commonList: {
    commonListTitle: '常用项管理',
    titleTips: '定义常用的巡检项，提高巡检计划制定和维护效率。',
    patItemDesc: '巡查项内容',
    category: '分类',
    default: '默认',
    patIntputType: '输入模式',
    needFeed: '必须反馈？',
    coldData: '采集数据？',
    refCount: '引用次数',
    deleteCommonSuccess: '巡查项已成功删除！',
    deleteSystemSuccess: '巡查项已成功删除！',
    commonListButton: '常用项',
    systemListButton: '系统常用项',
    comItemManQueryTips: '请输入巡查项内容',
    comCategoryQueryTips: '请输入分类',
    newPatItem: '新增巡查项',
    editPatItem: '编辑巡查项',
    emptyTips: '未找到相关常用项。',
  },
  commonListCreateCommon: {
    patItemDesc: '巡查项内容',
    categopy: '分类',
    patInputType: '输入模式',
    feedBackVal: '反馈可选项',
    addOne: '加一项',
    setTem: '从模板选择',
    tmplEmptyTips: '未找到相关模板。',
    saveTem: '保存为模板',
    inputLimit: '录入限制',
    defVal: '默认值',
    defValInfo: '用于设定手机APP端该巡查项的默认反馈结果。',
    isNeedFeedBack: '必须反馈',
    feedBackInfo: '如果该巡查项设为必须反馈，则巡检执行人在手机APP端反馈巡检结果时，该巡查项不能留空。',
    isColData: '采集（仪表）数据',
    colDataInfo: '如果是，可在“数据采集记录”应用中查询相应的读数记录及图表。',
    inputInsItemContentTips: '请输入巡查项内容',
    onehundredandfifty: '巡查项内容不能超过150字',
    pleaseSelectFeedBack: '请选择反馈',
    checkSelectValLen: '可选项不能超过9个！',
    createSuccess: '新的巡查项已成功添加！',
    editSuccess: '巡查项已成功修改！',
    addPatItem: '添加巡查项',

    isUploadPic: '允许上传图片',
    picClassify: '图片分类',
    defValNeedFromFeedback: '默认值需来自反馈可选项',
    setCom: '设为常用项',
    setComSuccess: '已成功设为常用项！',
  },
  woTemplate: {
    mainWoTemplate: '工单模板管理',
    woTemplateListTips: '定义工单模板的具体状态，包括巡检哪些巡检点，每个巡检点有哪些巡查项，以及工单的具体执行计划，由谁完成等。',
    pleaseInputWoTemName: '请输入工单模板名称或模板编号',
    woCatSelectTips: '请选择分类',
    template: '模板',
    name: '模板名称',
    suswo: '持续性工单',
    onTimeCompletion: '{count} 按时完成',
    overdueCompletion: '{count} 超时完成',
    cancelled: '{count} 已取消',
    notFollowUp: '{count} 未处理',
    assignee: '执行人',
    insman: '巡检执行人',
    reviewer: '复核人',
    categopy: '分类',
    createNewWoTem: '创建工单模板',
    createWo: '创建工单',
    deleteSuccess: '工单模板删除成功！',
    emptyTips: '未找到相关工单模板。',
  },
  woTemplateCreate: {
    tabName: '工单模板',
    woTemplateName: '工单模板名称',
    woTemplateNameEmptyTips: '工单模板名称不能为空',
    woTemplateNameExceedTips: '工单模板名称不能超过100字',
    workOrderTemplateNum: '工单模板编号',
    editWoTemplate: '编辑工单模板',
    woTemplateCat: '工单模板分类',
    woCatDesc: '分类描述',
    woCatSelectTips: '请选择分类',
    woRule: '工单规则制定',
    deleteLocation: '该巡检点已删除',
    deleteAsset: '该资产已删除',
    woIsEditLoc: '允许修改巡检点',
    woIsDelLoc: '允许删除巡检点',
    wosustainabilily: '持续性工单？',
    wosustainabililyTips: '没有执行计划时间，随时发起执行。且执行完成后即可生成一条同样的工单。',
    woNeedReviewer: '需要复核人',
    woReviewerInfo: '如果需要，工单操作完成后，流程会提交至指定的物业实体经理进行复核。',
    makeWarning: '未按时完成工单通知',
    needPhoto: '强制上传照片',
    needPhotoInfo: '如果勾选，则此巡检路线上所有巡点必须强制上传照片方为完成',
    remindIsAlertLabel: '记录到警报历史',
    scanAgain: '完成时需要再次扫描',
    scanAgainInfo: '如果勾选，则每个巡检点完成工作后需要再次扫描标签',
    warningCheckNeedRoute: '勾选 "高级设定" - "巡检路线必须按巡点次序" 后才生效',
    noCancel: '不允许取消',
    noCancelInfo: '如果勾选，则此工单不可强行取消。',
    taskRemindToFinish: '完成提醒',
    taskRemindToFinishTips: '开启以后，一旦巡检单/工单执行完成后，发送消息提醒给物业实体经理角色。',
    advanceSetting: '高级设定',
    ifSendWork: '允许给他人发起工单',
    autoCompuponScan: '扫描即完成 / 巡逻棒模式',
    autoCompuponScanInfo: '检查时，如果没有附加检查表，此巡检点将自动完成。这将方便于需要连续扫描的情况。如果未选中，则用户必须单击“完成”按钮才能转到下一个检查点。',
    needRoute: '巡检路线必须按巡点次序',
    needRouteInfo: '如勾选，则需要按照巡检点的序号来依次完成巡检。',
    calSignTime: '计算签到时间',
    createWoScanLocNecessary: '创建时巡检点需强制扫描',
    createWoScanLocNecessaryDescription: `从APP端用该模板创建工单的时候，如点击“替换”要替换巡检点的时候，一定要正确扫该巡检点的二维码/NFC/蓝牙/GPS成功后，方可替换过去`,
    addAsset: '添加资产',
    currencyLoc: '未知巡检点',
    assetsName: '资产名称',
    homeLoc: '巡检点',
    woTempClassNull: '工单模板分类不能为空',
    createSuccess: '工单模板成功创建！',
    editSuccess: '工单模板成功修改！',
    slaMenuTitle: 'SLA 配置',
    slaDescLabel: '优先级',
    slaFbtLabel: '反馈时限（分钟）',
    slaTitle: 'SLA（服务等级协议）配置',
    slaSearchPlhld: '请输入优先级',
    woRemind: '工单未执行提醒',
    woRemindTips: '勾上以后可设定超过计划执行时间仍然没有到场，即发送提醒消息给执行人。可设定超过计划执行时间多少时间的单位为分钟。如该执行人在指定时间内已有签到数据的话，认为已到场，无需提醒，反之发送提醒消息。',
    selectAssetNoDataTips: '请选择资产',
  },
  woTemplateDetail: {
    templateName: '模板名称',
    viewWoTemplate: '查看工单模板',
    woTemplateNum: '工单模板编号',
    woTemplateName: '工单模板名称',
    woTemplateCat: '工单模板分类',
    slaFbtLabel: '反馈时限（分钟）',
    wosustainabilily: '持续性工单？',
    woNeed: '工单需在',
    slaDescLongLabel: 'SLA 配置优先级',
    overBy: '超出',
    woAbnormal: '即为不正常',
    needPhoto: '强制上传照片',
    currencyLoc: '未知巡检点',
    deleteAsset: '该资产已删除',
    deleteLocation: '该巡检点已删除',
    meterNum: '仪器编号',
    woFive: '最近5次工单执行情况',
    woNum: '工单号',
    insplanTime: '计划巡检时间',
    actComTime: '实际完成时间',
    actExecutor: '实际执行人',
    status: '状态',
    foleventrep: '后续事件报告',
    woTemplateCopy: '复制为一个新的工单模板',
    insmanName: '巡检人姓名',
  },
  woList: {
    woList: '工单记录',
    wo: '工单',
    recNum: '单号',
    desc: '描述',
    details: '详细内容',
    category: '分类',
    generateTime: '工单生成时间',
    insplanTime: '计划执行时间',
    actStartTime: '实际开始时间',
    actComTime: '实际完成时间',
    overTime: '超时',
    executor: '执行人',
    location: '位置',
    unspec: '未指定',
    homeReviewed: '待审核',
    results: '结果',
    incReport: '{count} 事件报告',
    allInslist: '全部工单',
    today: '今天工单',
    nearlyaweekinslist: '近一周工单',
    nearlyonemonthinslist: '近一月工单',
    nearlyhreemonthsinslist: '近三月工单',
    normal: '正常',
    abnormal: '不正常',
    comOnTime: '已结束(已完成)',
    waitingStart: '待执行',
    inProgress: '进行中',
    pending: '暂搁置',
    peoCancelled: '人为取消',
    unProcess: '未处理',
    omitLoc: '巡点遗漏',
    notTimeRange: '非规定时间范围',
    createWo: '创建工单',
    createNewWoTem: '创建工单模板',
    selectStartDate: '请选择开始时间',
    selectEndDate: '请选择结束时间',
    woCatSelectTips: '请选择分类',
    woSearch: '请输入工单号、工单描述、工单执行人或位置',
    viewMapTrack: '查看巡检轨迹',
    viewWoTemplate: '查看工单模板',
    viewDetail: '查看详细',
    completeWO: '完成工单',
    completeWOConfirmTips: '请确认是否完成该工单？',
    completeWOSuccessTips: '工单成功完成！',
    emptyTips: '未找到相关工单。',
  },
  woListDetail: {
    viewWoDetail: '查看工单',
    print: '打印工单',
    printAbnormal: '打印工单(仅异常项)',
    details: '详细内容',
    viewPicDetail: '查看图片',
    pendingReason: '搁置理由',
    eventPic: '工单照片',
    picFailed: '加载失败',
    noPics: '暂无图片',
    noPicCatData: '暂无数据',
    viewMatdet: '查看物料',
    materialNum: '物料编号',
    matdetDesc: '描述',
    invtaddqty: '余量',
    materialwh: '仓库',
    invtshelf: '货架',
    smsType: '类型',

    matIssue: '发放库存项目',
    matReturn: '返回库存项目',
    matAvgcstadj: '平均成本调整',
    matCurbaladj: '当前余量调整',
    matInsertItem: '插入新库存项目',
    matReceipt: '接收库存项目',
    monitorList: '物联网传感器记录',
    sensorCode: '物联网传感器编码',
    sensorName: '物联网传感器名称',
    meterName: '仪表名称',
    monitorIndicesNum: '测量值',
    monitorIndicesDifference: '差异值',
    monitorIndicesDate: '时间',
    cancelWord: '取消工单',
    reason: '理由',
    enterCancelWord: '请输入取消工单理由',
    enterCancelWordTips: '请输入取消工单理由',
    successCancelWord: '该工单已取消成功',
    editWoTips: '如果修改在离线执行中的工单，已执行的巡检数据将会丢失。请确认是否要继续修改？可以让执行人在APP的工单执行页进行修改，以避免巡检数据丢失。',
    planExecutionDate: '计划执行时间',
    modifyRecords: '编辑记录',
    modifiedBy: '编辑人',
    modifiedTime: '编辑时间',
  },
  woCreate: {
    createWo: '创建工单',
    editWork: '编辑工单',
    createWoTips: '定义工单任务的具体内容，包括有哪些巡检点，每个巡检点有哪些巡查项，由谁完成等。',
    woOrderInfomation: '工单信息',
    woDesc: '工单描述',
    woName: '工单名称',
    woCat: '工单分类',
    woNameEmptyTips: '工单名称不能为空',
    woCatEmptyTips: '工单分类不能为空',
    woCatSelectTips: '请选择分类',
    woCatDesc: '分类描述',
    nextWoPlanTime: '计划执行时间为',
    woRule: '工单规则制定',
    woSustainability: '持续性工单？',
    woSustainabilityTips: '没有执行计划时间，随时发起执行。且执行完成后即可生成一条同样的工单。',
    woNeedReviewer: '需要复核人',
    reviewerEmptyTips: '复核人不能为空',
    woReviewerInfo: '如果需要，工单操作完成后，流程会提交至指定的物业实体经理进行复核。',
    makeWarning: '未按时完成工单通知',
    needPhoto: '强制上传照片',
    needPhotoInfo: '如果勾选，则此巡检路线上所有巡点必须强制上传照片方为完成',
    scanAgain: '完成时需要再次扫描',
    scanAgainInfo: '如果勾选，则每个巡检点完成工作后需要再次扫描标签',
    noCancel: '不允许取消',
    noCancelInfo: '如果勾选，则此工单不可强行取消。',
    taskRemindToFinish: '完成提醒',
    taskRemindToFinishTips: '开启以后，一旦巡检单/工单执行完成后，发送消息提醒给物业实体经理角色。',
    ifSendWork: '允许给他人发起工单',
    autoCompuponScan: '扫描即完成 / 巡逻棒模式',
    autoCompuponScanInfo: '检查时，如果没有附加检查表，此巡检点将自动完成。这将方便于需要连续扫描的情况。如果未选中，则用户必须单击“完成”按钮才能转到下一个检查点。',
    needRoute: '巡检路线必须按巡点次序',
    needRouteInfo: '如勾选，则需要按照巡检点的序号来依次完成巡检。',
    calSignTime: '计算签到时间',
    selectPic: '工单照片',
    picTips: '根据您所购买的版本，每张工单最多可上传 {count} 张照片，图片大小应在1m以内。',
    details: '详细内容',
    slaFbtLabel: '反馈时限（分钟）',
    slaDescLongLabel: 'SLA 配置优先级',
    implementationDateRequired: '执行日期是必需的',
    isNeedToChangeLocTips: '尚未指定工单位置，是否确定创建工单？',
    duse: '启用',
    goSpecify: '去指定',
    createUnknownPointBtn: '创建未知位置工单',
    createSuccess: '工单成功创建！',
    editSuccess: '工单成功修改！',
  },
  punchInTemplate: {
    mainSignInTemplate: '打卡签到模板管理',
    pleaseInputSignInTemplate: '请输入打卡签到模板名称',
    signInTemplateCategory: '模板类别',
    tmplName: '模板名称',
    setSignInTemplateInsexecutor: '执行人',
    punchInMethod: '打卡方式',
    deleteSuccess: '打卡签到模板成功删除！',
    emptyTips: '未找到相关打卡签到模板。',
    valid: '有效',
    invalid: '无效'
  },
  punchInTemplateCreate: {
    createSignInTemplate: '创建打卡签到模板',
    editSignInTemplate: '编辑打卡签到模板',
    signInTemplateName: '打卡签到模板名称',
    signInTemplateCategory: '模板类别',
    setSignInTemplateInsexecutor: '执行人',
    clockInfrequency: '最小打卡时间间隔',
    clockInfrequencyTips1: '最小打卡时间间隔是设置再次打卡同一巡逻点的最短时间。 防止误打卡或误报工作。',
    clockInfrequencyTips2: '例如：当间隔设置为10秒时， 用户A打卡时， 只允许在前一次打卡10秒后进行第二次打卡。',
    punchInMethod: '打卡方式',
    signInDesRequired: '请输入打卡签到模板名称',
    assignGroupRequired: '执行人不能为空',
    minimumInterval: '请输入正确的最小打卡时间间隔',
    pointArrivalTit: '每日巡检点到场/离场签到时间管理',
    addPatPoint: '添加巡检点',
    time: '时间',
    desc: '描述',
    alertWarning: '警报/警告',
    addTimeManagement: '添加签到时间管理',
    createSuccess: '打卡签到模板已成功添加！',
    editSuccess: '打卡签到模板已成功编辑！',
  },
  punchInTemplateAddInstrument: {
    addTimeManagement: '添加签到时间管理',
    editTimeMamagement: '编辑签到时间管理',
    time: '时间',
    desc: '描述',
    alertWarning: '警报/警告',
    requiredTime: '请选择时间',
    requiredDescription: '请选择描述',
    requiredAlart: '请选择警告',
    requiredTimeManagement: '请添加签到时间管理',
  },
  monitorTemplate: {
    mainMonitoringTemplate: '监控模板管理',
    templateName: '监控模板',
    monitorMainTemplateNanceexplain: '这是为了管理每个传感器的监控规则，更新高水位线或低水位线以触发特定的工单进行跟进。',
    monitorQueryTips: '请输入监控模板名称或传感器编码',
    sensorCode: '物联网传感器编码',
    deleteSuccess: '监控模板成功删除！',
    disabled: '无效',
    enabled: '有效',
    disable: '关闭',
    enable: '启动',
    emptyTips: '未找到相关监控模板。',
  },
  monitorTemplateCreate: {
    createMonitoringTemplate: '创建监控模板',
    editmonitor: '编辑监控模板',
    monitoringName: '监控模板名称',
    meterItem: '传感器',
    selectMeter: '选择传感器',
    sensor: '物联网传感器',
    sensorName: '物联网传感器名称',
    sensorCode: '物联网传感器编码',
    sensorDesc: '描述',
    operationUpperLimit: '作为操作上限',
    operationLowerLimit: '作为操作下限',
    operationEqualLimit: '作为操作等于',
    operationIncrement: '作为操作增量',
    addMeterIndices: '添加仪表',
    meter: '仪表',
    alertValue: '警告值',
    workOrderTemplate: '工单模板',
    pleaseInputMonitoringName: '请输入监控名称',
    monitoringNameExceedTips: '监控名称不能超过50字',
    createSuccess: '新的监控模板成功添加！',
    editSuccess: '监控模板成功编辑！',
  },
  monitorTemplateAddMeter: {
    meter: '仪表',
    alertValue: '警告值',
    operationUpperLimit: '作为操作上限',
    operationLowerLimit: '作为操作下限',
    operationEqualLimit: '作为操作等于',
    startDate: '开始时间',
    endDate: '结束时间',
    workOrderTemplate: '工单模板',
    isAllowCreateNew: '在上次工单未完成前允许创建新的工单',
    requiredMeter: '请选择仪表',
    requiredWarnPoint: '请填写警告值',
    requiredOperation: '必须选择其中一项操作项',
    requiredCharacteristic: '特征仪表只能输入英文',
    requiredNotCharacteristic: '只能输入数字',
    selectWorkOrderTemplate: '选择工单模板',
    addMeter: '添加仪表',
    editMeter: '编辑仪表',
    remindMethod: '自动经以下方式提醒',
    voice: '语音提醒',
    sms: '短信提醒',
    mobile: 'App推送'
  },
  inspectionRecords: {
    insorder: '巡检单记录',
    insorderTips: '查看具体的巡检执行情况',
    insorderQueryTips: '请输入巡检单单号或描述或巡检执行人',
    status: '状态',
    statusFilter: '状态筛选',
    inspOrder: '巡检单',
    recNum: '单号',
    description: '描述',
    dateTime: '日期',
    insPlanTime: '计划巡检时间',
    actStartTime: '实际开始时间',
    actComTime: '实际完成时间',
    overTime: '超时',
    assignee: '执行人',
    insMan: '巡检执行人',
    unspec: '未指定',
    actExecutor: '实际执行人',
    reviewer: '复核人',
    homeReviewed: '待审核',
    track: '追踪',
    inspResults: '巡检结果',
    resetStatus: '重置状态',
    viewDetail: '查看详细',
    viewInstaskDetail: '查看巡检计划',
    viewMapTrack: '查看巡检轨迹',
    trackEmptyTips: '暂无巡检轨迹',
    rcEndedCompleted: '已结束(已完成)',
    end: '已结束',
    comp: '已完成',
    wfProcess: '待巡检',
    inProgress: '进行中',
    pending: '暂搁置',
    cancelled: '已取消',
    rcunprocess: '超时未处理',
    rcAbnormal: '异常',
    rcOmitLoc: '漏巡',
    increport: '事件报告',
    rcWorkOrder: '工单',
    rcNotTimeRange: '非规定时间到达',
    selectStartDate: '请选择开始时间',
    selectEndDate: '请选择结束时间',
    allInslist: '全部巡检单',
    nearlyaweekinslist: '近一周巡检单',
    nearlyonemonthinslist: '近一月巡检单',
    nearlyhreemonthsinslist: '近三月巡检单',
    recordDate: '记录时间',
    createNewTask: '创建新巡检计划',
    emptyTips: '未找到相关巡检单。',
  },
  inspectionRecordsDetail: {
    viewinsListDetail: '查看巡检单',
    reportPrint: '报表打印',
    printins: '打印巡检单',
    printinsExc: '打印巡检单(仅异常项)',
    insNum: '巡检单号',
    insDesc: '巡检描述',
    inscreateTime: '巡检单生成时间',
    actStartTime: '实际开始时间',
    insplanTime: '计划巡检时间',
    actcomtime: '实际完成时间',
    overMinToComp: '超 {count} 分钟完成',
    completeStatus: '完成状态',
    normal: '正常',
    abNormal: '不正常',
    setAbnormal: '设置为不正常',
    setNormal: '设置为正常',
    normalChangeBy: '修改人',
    normalChangeDate: '修改时间',
    changeNormalReason: '更改理由',
    insman: '巡检执行人',
    multipleExecute: '允许多人协作巡检？',
    unspec: '未指定',
    actExecutor: '实际执行人',
    reviewer: '复核人',
    homeReviewed: '待审核',
    mileage: '里程数',
    kilo: '公里',
    cancelReason: '取消原因',
    partinsReason: '部分巡检原因',
    increportnum: '事件编号',
    increportcontent: '事件报告内容',
    reporttime: '报告时间',
    inccomptime: '处理完成时间',
    handleResult: '处理结果',
    status: '状态',
    detail: '详细',
    withIncreps: '有 {count} 项事件报告',
    insfeedback: '巡检情况反馈',
    deleteLocation: '该巡检点已删除',
    nonpat: '未巡查',
    insAbnormal: '巡查异常',
    locRcWoHitsory: `该异常巡点已创建 {count} 次工单`,
    skipReasonTips: '因故跳过',
    situafeedback: '情况反馈',
    arrivalTime: '应签到时间段',
    skipReason: '跳过原因',
    reviewSkipReason: '复核人跳过理由',
    gpsReason: '未到达定位点理由',
    registration: '应签到时间段',
    earlyMinutes: '提早了',
    lateMinutes: '迟到了',
    reason: '理由',
    reviewReason: '复核人理由',
    inputReason: '请输入理由',
    allInspat: '全部巡检点',
    onlyAbnormal: '仅显示异常巡检点',
    onlyPatrolled: '仅显示已巡检点（包括异常）',
    onlyUnpat: '仅显示未巡检点',
    position: '巡检点位置',
    patPointName: '巡检点名称',
    patPointNameTips: '巡检点的名称，用于定义要检查的物理位置或资产',
    patPointNum: '巡检点编号',
    patPointNumTips: '巡检点编号',
    patPointType: '标识类型',
    patPointTypeTips: '巡检点的类型，可以是NFC，QR码（二维码），蓝牙或GPS',
    errorRange: '误差范围',
    errorRangeUnit: '米',
    mapPoint: '巡点位置',
    lng: '经度',
    lat: '纬度',
    isUseLabel: '巡检单需要扫描标签方可开启巡检',
    isUseLabelTips: '启用后，强制检查员在到达时扫描巡逻点以表示在巡逻点现场',
    isEffective: '有效？',
    isEffectiveTips: '一旦无效以后，新生成的巡检单与工单中将不包含该巡检点',
    viewImgDigLog: '图片详情',
    viewWOrDigLog: '工单历史',
    woNum: '工单号',
    woDesc: '工单描述',
    woCreateTime: '工单生成时间',
    sponsor: '发起人',
    signature: '签名',
  },
  signInList: {
    signInList: '打卡签到记录',
    createSignInTemplate: '创建打卡签到模板',
    enterSearch: '请输入巡检点或登入账号',
    signInTemplateApplyNo: '流水号',
    signInTemplateInputName: '打卡签到模板名称',
    signInTemplatePatrolPointName: '巡检点',
    signInTemplatePunchTime: '预计打卡签到时间',
    signInTemplatePatrolTime: '实际打卡签到时间',
    type: '类型',
    alertWarning: '警报/警告',
    taskTemplateLoginName: '登入账号',
    signInTemplateLockUser: '实际执行人',
    emptyTips: '未找到相关打卡签到。',
  },
  sensorReading: {
    monitorList: '物联网传感器记录',
    sensorCode: '物联网传感器编码',
    sensorName: '物联网传感器名称',
    sensorDesc: '描述',
    sensorQueryTips: '请输入物联网传感器名称、描述或编码',
    instrumentReadingRecode: '这是从物联网传感器收集的历史记录，提供传感器的读数。',
    emptyTips: '未找到相关物联网传感器。',
  },
  sensorReadingDetail: {
    monitorList: '物联网传感器记录',
    securitySensorsTit: '物联网传感器',
    sensorCode: '物联网传感器编码',
    sensorName: '物联网传感器名称',
    monitorNum: '监控模板编号',
    monitorName: '监控模板名称',
    sensorDesc: '描述',
    meterPic: '照片',
    sensorPicError: '加载失败',
    monitorIndices: '仪表',
    monitorIndicesNum: '测量值',
    monitorIndicesUnit: '单位',
    monitorIndicesDifference: '差异值',
    monitorIndicesDate: '时间',
    meter: '仪表',
  },
  event: {
    eventreplist: '事件报告记录',
    eventreplistTips: '查看和跟踪巡检过程中发现的事件报告',
    incNum: '编号',
    content: '内容',
    position: '巡检点',
    reporter: '报告人',
    reportTime: '报告时间',
    priority: '重要性',
    results: '结果',
    handler: '处理人',
    handlerResult: '处理结果',
    eventreplistQueryTips: '请输入事件报告单号或描述或巡检点或处理人',
    showWep: '仅显示待处理报告',
    setEmailNotice: '设置邮件通知',
    setEmailNoticeTips: '设置邮件通知后，事件报告产生时，系统会以Email的方式发送给以下人员。允许设定多邮箱，中间请使用英文逗号（,）分隔，',
    setEmailNoticeSuchTips: '如：abc@313fm.com, lucy@313fm.com, tom@313fm.com',
    emailAddress: '邮箱地址',
    eamilAddressError: '邮箱地址格式不正确',
    allIncreport: '全部事件报告',
    nearlyaweekinc: '近一周事件报告',
    nearlyOneMonthinc: '近一月事件报告',
    nearlyThreeMonthsInc: '近三月事件报告',
    setEmailSuccess: '邮箱保存成功！',
    viewDetail: '查看详细',
    reportResults: '快速汇报结果',
    comp: '已完成',
    can: '已取消',
    wfp: '待处理',
    emptyTips: '未找到相关事件报告。',
  },
  eventDetail: {
    viewIncReportDetail: '查看事件报告',
    increportNum: '事件编号',
    increportDesc: '事件内容',
    increportPos: '发生位置',
    reporter: '报告人',
    reportTime: '报告时间',
    cancelTime: '取消时间',
    incComptime: '处理完成时间',
    handleResult: '处理结果',
    dealerout: '处理人/外包商',
    increportesultman: '汇报结果操作人',
    increportTime: '汇报结果操作时间',
    increportImg: '事件照片',
    increportVideo: '事件视频',
    increportAudio: '事件录音',
    increportResult: '汇报结果',
  },
  eventCollect: {
    increportDesc: '事件内容',
    incComptime: '处理完成时间',
    handleResult: '处理结果',
    dealerout: '处理人/外包商',
    handleComp: '处理完成',
    notNeedCancel: '无需处理取消',
    notNeedCancelConfirmTips: '请确认是否取消该事件？',
    processPhoto: '处理后照片',
    processVideo: '处理后视频',
    clickUpload: '点击上传',
    imageTypeTips: '只能上传 .apng、.avif、.gif、.jpg、.jpeg、.jfif、.pjpeg、.pjp、.png、.webp、.svg、.webp 格式的照片。',
    maxSizeTips: '{name} 超出最大限制。',
  },
  dataList: {
    datacolrecord: '数据 / 仪表读数',
    datacolrecordTips: '查看标注为数据采集项的巡检点的数据采集历史',
    datacolrecordQueryTips: '请输入巡检点位置或数据采集项',
    patPointLoc: '巡检点位置',
    dataColitem: '数据采集项',
    deleteLocation: '该巡检点已删除',
    emptyTips: '未找到相关数据 / 仪表读数。',
    view: '查看数据采集',
  },
  dataListDetail: {
    title: '数据采集明细',
    patPointLoc: '巡检点位置',
    datacolitem: '数据采集项',
    datacolTime: '采集时间',
    datacolVal: '采集数值',
    inslist: '巡检单',
    instask: '巡检计划',
    emptyTips: '未找到相关数据。',
  },
  alertHistory: {
    rcAlert: '警报历史',
    alert: '警报',
    recnum: '单号',
    desc: '描述',
    executor: '执行人',
    alertLoc: '警报地点',
    alertMsg: '警报内容',
    alertTime: '警报时间',
    results: '结果',
    handler: '处理人',
    handleContent: '处理内容',
    handleDate: '处理时间',
    rcAlertSearchEnterTips: '请输入单号或描述或警报内容或处理人',
    selectStartDate: '请选择开始时间',
    selectEndDate: '请选择结束时间',
    viewDetail: '查看详细',
    viewinsListDetail: '查看巡检单',
    viewWoDetail: '查看工单',
    handleAlertInfo: '处理警报信息',
    processed: '已处理',
    unprocessed: '未处理',
    emptyTips: '未找到相关警报。',
  },
  alertHistoryHandleAlerts: {
    handleAlertInfo: '处理警报信息',
    insDesc: '巡检描述',
    alertLoc: '警报地点',
    alertMsg: '警报内容',
    handleDate: '处理时间',
    handleContent: '处理内容',
    handler: '处理人',
    incresultover: '内容不能超过100字',
    placeholderHandler: '处理人不能为空',
  },
  alertHistoryDetail: {
    viewRcAlert: '查看警报信息',
    alertNum: '警报编号',
    insDesc: '巡检描述',
    insMan: '巡检执行人',
    alertLoc: '警报地点',
    alertTime: '警报时间',
    alertMsg: '警报内容',
    handler: '处理人',
    handleDate: '处理时间',
    handleContent: '处理内容',
  },
  guestQrcode: {
    guestQrcode: '访客接待 / 入口管理',
    guestManageDescription: '此物业实体的访客拜访入口点。例如：XX 大楼1号门入口',
    enterSearch: '请输入名称',
    addGuestQrcode: '新增拜访入口点',
    locTips: '请选择巡检点',
    configUserName: '名称',
    shareByEmail: '经邮件分享',
    shareByEms: '经短信分享',
    emailAddress: '邮箱地址',
    emailSetErrMsg: '请输入正确的邮件地址',
    inputAndEnter: '请输入内容并按下回车键，再点击发送',
    sendSuccess: '发送成功',
    secondresend: `{seconde} 秒后重新发送`,
    correctAlertPhone: '请输入正确的电话号码',
    selPhoneNum: '手机号码',
    detail: '详细内容',
    guestBookIngUrl: '预约登记链接',
    entranceQrcodeTips: '请打印此二维码并置于入口处，方便客户拜访时扫描并填写资料',
    homeLoc: '巡检点',
    visitorQuestionTmpl: '访客问题模板',
    emptyTips: '未找到相关拜访入口点。',
    shareTips: '访客预约信息有更改，请保存后再分享',
  },
  guestReserve: {
    guestReserve: '访客预约记录',
    guestReserveDescription: '这里显示的是所有访客的预约记录，包括过去和将来的预约。任何访客经链接提交的预约记录都会在这里展示。',
    pleaseSelectStatus: '请选择状态',
    guestReserveName: '来访者姓名',
    guestReserveTime: '来访预约时间',
    guestCompany: '访客公司',
    contactPerson: '联系人',
    guestCount: '来访次数',
    guestStatus: '状态',
    startDate: '开始时间',
    endDate: '结束时间',
    guestCompanyNullMsg: '请输入访客公司名称',
    confimCancelGuest: '确定取消预约？',
    cancelSuccess: '成功取消预约！',
    guestReserveDetail: '访客信息',
    guestLocalAddress: '来访入口',
    guestName: '来访者姓名',
    guestBookingTime: '来访预约时间',
    guestPhone: '联系方式',
    guestIdCard: '证件号码',
    guestMember: '随行人员',
    depVisitedComplany: '被访公司或部门',
    guestPurpose: '来访目的',
    guestContact: '被访人姓名',
    guestContactPhone: '被访人电话',
    guestQrcodeValidTime: '二维码有效期',
    guestQrcodeValidTimepl: '仅在该期间内，拜访所访公司时出示的二维码有效。',
    visitQrcode: '来访预约码',
    emptyTips: '未找到相关访客预约。',
  },
  guestHistory: {
    visitorQuestionnair: '访客问题模板',
    visitHistory: '访客来访历史',
    visitHistoryDescription: '登记预约的访客来访后，出示二维码的历史记录。',
    guestCompanyNullMsg: '请输入访客公司名称',
    guestReserveName: '来访者姓名',
    guestReserveTime: '来访预约时间',
    visitTime: '来访时间',
    guestCompany: '访客公司',
    contactPerson: '被访人姓名',
    isEntryAllow: '允许进入？',
    isAllow: '允许',
    isNotAllow: '不允许',
    setting: '设置',
    keepRecordsByDay1: '只保留 ',
    keepRecordsByDay2: ' 天的来访历史',
    daysNoNull: '天数不能为空',
    pleaseInputMinMaxNumber: `请输入 {min} 至 {max} 之间的整数`,
    editSuccess: '修改成功',
    emptyTips: '未找到相关访客来访。',
  },
  guestRegister: {
    guestBooking: '访客预约',
    guestRegister: '访客登记',
    guestBookingTimeStartNullMsg: '请选择日期',
    guestBookingTimeEndNullMsg: '请选择时间',
    guestNameNullMsg: '请输入访客姓名',
    guestFormLengTherrmsg: `长度在 {min} 到 {max} 个字符之间`,
    guestCompanyNullMsg: '请输入访客公司名称',
    guestAddressNullMsg: '请输入具体地址',
    guestDepVisitedCompanyMsg: '请输入到访公司或部门',
    guestPurposeNullMsg: '请输入来访目的',
    guestContactNullMsg: '请输入联系人姓名',
    guestContactPhoneNullMsg: '请输入联系人电话',
    guestQrcodeValidTimeNullMsg: '请选择二维码有效期范围',
    qrCodeTimeoutMsg: '二维码的有效期不能早于预定时间',
    guestBookingTime: '来访预约时间',
    pleaseSelectDate: '选择日期',
    pleaseSelectTime: '选择时间',
    guestName: '来访者姓名',
    guestPhone: '联系方式',
    guestIdCard: '证件号码',
    guestMember: '随行人员',
    guestMemberpl: '如随行人员姓名及联系方式',
    guestCompany: '访客公司',
    depVisitedCompany: '被访公司或部门',
    guestPurpose: '来访目的',
    guestContact: '被访人姓名',
    guestContactPhone: '被访人电话',
    guestQrCodeValidTime: '二维码有效期',
    to: '至',
    startDate: '开始时间',
    endDate: '结束时间',
    guestPhotosTips: '只能上传 .jpg、.jpeg、.png 格式的照片，且不超过8MB',
    guestQrcodeValidTimepl: '仅在该期间内，拜访所访公司时出示的二维码有效。',
    checkGuestQrcode: '查看预约二维码',
    repeatSubimtFailMsg: '不能提交相同的数据，如需重新申请，请至少修改一项数据',
    visitregisterSuccess: '登记成功',
    guestQrCode: '访客接待 / 入口管理',
    bookingSuccess: '预约成功',
    bookingSuccessDescription: '请将该二维码拍照，并发送给来访者妥善保管，来访当日需出示给管理处人员',
  },
  workTableLog: {
    gwrzlb: '岗位日志列表',
    gwrzDesc: '有些物业下的巡检人员，是需要将每天做的工作（巡检/工单），每天发生的事情都记录下来，叫做岗位日志。',
    searchLog: '请输入日志发生位置或日志内容或执行人',
    selectStartDate: '请选择开始时间',
    selectEndDate: '请选择结束时间',
    logStartDate: '事件开始时间',
    logEndDate: '事件结束时间',
    logSource: '来源',
    logContentDetail: '内容详细',
    logLoc: '位置',
    logExeName: '执行人名称',
    logInputTime: '记录时间',
    createLog: '创建日志',
    workOrder: '工单',
    inspectionForm: '巡检单',
    jobLog: '岗位日志',
    eventReport: '事件报告',
    emptyTips: '未找到相关岗位日志。',
  },
  workTableLogCreate: {
    addLog: '新增日志',
    editLog: '编辑日志',
    logDetail: '日志详情',
    logStartDate: '事件开始时间',
    logEndDate: '事件结束时间',
    logLoc: '位置',
    logContentDetail: '内容详细',
    logPic: '照片',
    logPics: '照片',
    logHr: '执行人账号',
    logHrName: '执行人名称',
    logInputTime: '输入时间',
    logType: '事件种类',
    logSource: '来源',
    logOddNumbers: '单号',
    logPatPonit: '巡检点',
    pleaseSelectOrInput: '请选择或输入事件种类',
    assetsName: '资产名称',
    homeLoc: '巡检点',
    logPlace: '巡检点',
    logStartTime: '巡查时间',
    nullTips: '请选择或输入带*的必填选框',
    createSuccess: '新的岗位日志已成功创建！',
    editSuccess: '岗位日志已成功编辑！',
  },
  fileUpload: {
    documentationLibrarylist: '文档库列表',
    documentationDetails: '此功能是为了让物业实体经理向同一物业实体下的用户发布文档。用户可以从移动或网页界面访问文档。管理员可以定义文档描述、类别、目标用户组、推送通知和用户确认。',
    fileName: '文档名称',
    fileDescription: '文档描述',
    fileType: '类别',
    fileAttributeEntity: '物业实体',
    fileTargetUserGroup: '目标使用者组',
    filePostToEveryone: '发布到所有人',
    filePushNotification: '推送通知',
    fileUserConfirmationIsRequired: '需要使用者确认',
    fileReleaseTime: '发布时间',
    allUsers: '所有使用者',
    fileUploadEnterRsearch: '请输入文档名称或描述搜索',
    deleteSuccess: '文档删除成功！',
    withdrewSuccess: '文档撤回成功！',
    releaseSuccess: '文档发布成功！',
    confirmWithdrawFile: '请确认是否撤回文件',
    confirmReleaseFile: '请确认是否发布',
    withdraw: '撤回',
    release: '发布',
    emptyTips: '未找到相关文档。',
  },
  fileUploadPreview: {
    uploareadTip: '已阅读内容，请点击确认',
    uploadAutographName: '用户名称',
    namePlh: '请填写阅读人的名称',
    actexecutorRequried: '用户名是必填项',
    actexecutorExceed: '用户名不能超过50字',
  },
  fileUploadCreate: {
    createDocumentationLibrary: '创建文档',
    editDocumentationLibrary: '编辑文档',
    fileName: '文档名称',
    uploadFilesSync: '上传文件即可同步名称',
    fileDescription: '文档描述',
    fileType: '类别',
    pleaseSelectCategory: '请选择类别',
    fileAttributeEntity: '物业实体',
    fileTargetUserGroup: '目标使用者组',
    fileUserConfirmationIsRequired: '需要使用者确认',
    filePushNotification: '推送通知',
    allUsers: '所有使用者',
    selectWorkGroup: '选择工作组',
    // everyPushTime: '每{day}天，推送次数最多为{resendnum}次。',
    everyPushTime: '推送次数为{resendnum}次，每隔{day}天推送一次，保存设置后即推送一次。',
    doNotPush: '不推送',
    pushOnce: '推送一次',
    pushMultiple: '推送多次',
    everyDay: '每天',
    pleaseUploadFile: '请上传文件',
    pleaseFileContent: '请输入文档描述',
    pleaseFileContentExceed: '文档描述不能超过200字',
    pleaseSelectResendNum: `推送重发次数不能超过{resendnum}`,
    pleaseSelectResendNumNull: `推送重发次数不能为0`,
    createSuccess: '文档成功创建！',
    editSuccess: '文档已成功编辑！',
  },
  fileUploadTable: {
    fileUploadList: '文档访问记录',
    fileTableEnterRsearch: '请输入文档名称或描述搜索',
    fileName: '文档名称',
    fileDescription: '文档描述',
    fileAttributeEntity: '物业实体',
    fileUploadUser: '使用者名',
    fileUploadOpenDate: '首次读取时间',
    fileUploadConfirmDate: '已阅确认时间',
    fileUploadOpenNum: '读取次数',
    emptyTips: '未找到相关文档。',
  },
  fileUploadTableDetail: {
    uploadHistoryList: '历史详情',
    uploadExecutor: '用户名称',
    uploadViewTime: '访问时间',
    accessRecords: '访问记录',
  },
  fileUploadMyHistory: {
    title: '我的文档',
    all: '全部',
    read: '已读',
    unread: '未读',
    fileDescription: '文档描述',
    fileType: '类别',
    fileUserConfirmation: '使用者已确认',
    fileReleaseTime: '发布时间',
    fileUploadRead: '已读取？',
  },
  selectPunchIn: {
    selectPunchInTemplate: '选择打卡模板',
    punchInTemplateSearch: '请输入打卡模板名称',
    punchInTemplateInputName: '打卡模板名称',
    punchInTemplateNum: '打卡模板编号',
  },
  selectRcWo: {
    inslist: '巡检单',
    patNum: '巡检单号',
    insplanTime: '计划巡检时间',
    executor: '执行人',
    enterSearch: '请输入搜索内容',
  },
  rptCenter: {
    rptCenter: '报表中心',
    rptCenterTips: '平台将逐渐丰富各类统计报表，如现有报表格式不满足您的业务需求，可通过右侧“在线咨询”，把相关格式提交给我们，我们的产品经理将第一时间分析其可行性，也许很快它就可以出现在这里，服务您以及其他与您有相同需求的用户。',
    rptNameTips: '请输入报表名称',
    rptName: '报表名称',
    rptView: '效果预览',
    runCount: '运行次数',
    classification: '分类',
    classificationPlh: '请选择分类',
    nameSort: '按名称排序',
    frequencySort: '按次数排序',
    run: '运行',
    runRpt: '运行报表',
    autoEmailSetting1: '自动发送',
    autoEmailSetting2: '模板设置',

    runFieldRequired: '{field} 是必需的',
    yearMonthDay: '年月日',
    yearMonth: '年月',
    rptYear: '年',
    startDate: '开始时间',
    endDate: '结束时间',
    startEndDateTime: '开始和结束时间',
    startEndMonth: '开始和结束月份',
    insman: '巡检执行人',
    executor: '执行人',
    patPointLoc: '巡检点位置',
    woTemplate: '工单模板',
    punchInTemplate: '打卡模板',
    locNoPatrol: '巡检点未巡',
    newCreate: '最新创建',
    patPointName: '巡检点名称',
    patPointNum: '巡检点编号',
    checkOutPhoti: '输出照片',
    fileName: '文档名称',
    contentDescription: '内容描述',
    noout: '无需输出巡查项',
    fileUploadUser: '使用者名',
    logSource: '来源',
    accountGroup: '工作组',
    iffy: '按巡检点分页',
    instask: '巡检计划',
    homeLoc: '巡检点',
    rcWoStatus: '巡检单状态',
    showGpsCheckbox: '显示GPS坐标',
    showDescCheckbox: '时间降序',
    taskStartDate: '计划开始时间',
    taskEndDate: '计划结束时间',
    taskStartEndDateTime: '计划开始和结束时间',
    stentValDate: '请选择计划开始和结束时间或者实际开始和完成时间',
    taskFinishStartDate: '实际开始时间',
    taskFinishEndDate: '实际完成时间',
    actualStartCompletionDateTime: '实际开始和完成时间',
    rptparamsfb: '仅显示有反馈结果',
    abnormalPonitOnly: '仅显示巡检异常',
    rptExestatus: '执行状态',
    rptExestatusNoComp: '只输出未完成',
    rptExestatusComp: '只输出已完成',
    missingPointPrompt: '巡检点遗漏提示',
    onlyerrcwo: '仅显示含事件报告的巡检单',
    pagebreak: '按巡检点分页',
    inslist: '巡检单',
    result: '反馈结果',
    isShowPic: '显示巡检照片',
    isShowEventReport: '显示事件报告',
    isShowDetail: '显示详细',
    noarrival: '仅显示未按时到达',
    noontime: '仅显示未按时完成',
    timeType: '时间类型',

    // 报表中心自定义参数
    rpcustomparam: '自定义参数',
    rpwonum: '工单编号',
    rpwodesc: '工单描述',

    emptyTips: '未找到相关报表。',
  },
  rptCenterTemplateCreate: {
    description: '描述',
    tempLang: '语言',
    tempParams: '参数',
    createTempTips: '请输入描述信息',
    autoMailSet: '自动邮件发送设定',
    emailSubject: '邮件主题',
    sendCycle: '发送周期',
    cycled: '每天',
    cyclew: '每周（周一）',
    cyclem: '每月（1号）',
    schedule: '发送时间',
    sendTime: '数据时间段',
    sendTimeDayBefore: '前一天的数据',
    sendTimeLastWeek: '前一周的数据',
    sendTimeLastMonth: '上个月的数据',
    sendTimeCustomTime: '自定义时间段',
    dayBefore: '前一天',
    today: '今天',
    weekAgoDay: '一周前当天',
    setEmail: '邮箱地址',
    emailSetErrMsg: '请输入正确的邮件地址',
    emailSetErrMsg2: '最少输入一个邮箱地址',
    lessThan24HoursTips: '时间差必须小于24小时',
    endTimeLessThanStartTimeTips: '结束时间必须晚于开始时间',
  },
  rptCenterTemplate: {
    searchByDescription: '按描述搜索',
    description: '描述',
    creator: '创建人名称',
    create: '创建',
  },
  rcwosum: {
    insexeeffTitle: '巡检执行效率仪表板',
    nearlySevenDays: '近7天',
    nearlyThirtyDays: '近30天',
    nearlyNinetyDays: '近90天',
    thisMonth: '本月',
    lastMonth: '上月',
    thisQuarter: '本季度',
    lastQuarter: '上一季度',
    thisYear: '本年度',
    prevYear: '上一年度',
    statusTitle: '巡检单状态',
    xAxis: '计划执行时间',
    yAxis: '数据单数量',
  },
  eventsum: {
    eventRepstatiTitle: '事件报告仪表板',
    today: '今天',
    nearlySevenDays: '近7天',
    nearlyThirtyDays: '近30天',
    nearlyNinetyDays: '近90天',
    thisMonth: '本月',
    lastMonth: '上月',
    thisQuarter: '本季度',
    lastQuarter: '上一季度',
    thisYear: '本年度',
    prevYear: '上一年度',
    xAxis: '報告時間',
    yAxis: '數量',
  },
  responseTimeDashboard: {
    responsetimeTitle: '工单响应仪表板',
    responSetimeItemTitle: '响应时间',
    wocatSelectTips: '请选择分类',
  },
  locsum: {
    patabnstaTitle: '巡查异常仪表板',
    topTen: '前10',
    xAxis: '巡检时间',
    yAxis: '异常巡检点数量',
  },
  hrsum: {
    staffeffTitle: '人员效率仪表板',
  },
  releaseHistory: {
    releaseHistory: '版本历史记录',
    updateStatisics: '更新统计',
    lastUpdateDate: '最新更新日期',
    updateNum: '近一年更新次數',
    updateRecords: '更新记录',
    updateDate: '更新日期',
    updateLog: '更新日志',
    showInfo: '显示信息',
    lastMonth: '近一个月',
    lastThreeMonth: '近三个月',
    halfaYear: '近半年',
    lastYear: '近一年',
  },
  // 安全规则
  securityRules: {
    securityRulesTitle: '安全卫士',
    securityRulesTitleTips: '安全卫士能即时监控人员工作状态，若在固定时间内（如10分钟）无移动，系统将呼叫机主一段时间（如2分钟）。机主若清醒，轻轻摇动电话即可清除呼叫；若无反应，系统将向后台警报。此功能大幅提升现场工作人员（包括清洁、保安、工程师等独自工作人员）的职业安全保障。',
    enterSearch: '请输入安全卫士名称',
    createSecurityRules: '创建安全卫士',
    editSecurityRules: '编辑安全卫士',
    securityRulesName: '安全卫士名称',
    groupNumberOfHr: '成员数量',
    securityMonitoringInterval: '长时间不移动时间阈值（分钟）',
    securityMonitoringDelay: '客户响应时间阈值（分钟）',
    securityLastName: '最后修改人',
    securityLastTime: '最后修改时间',
    confirmDelete: '请确认是否删除该记录？',
    deleteSuccess: '安全卫士已成功删除！',
    emptyTips: '未找到相关安全卫士。',
  },
  securityRulesCreate: {
    securityRulesName: '安全卫士名称',
    securityRulesNameTips: '请输入安全卫士名称',
    securityRulesModel: '激活方式',
    securityRulesModelLogin: '登录时',
    securityRulesModelLoginTips: '当用户登录之后，系统开始监测，直到登出',
    securityRulesModelRcwo: '巡检时',
    securityRulesModelRcwoTips: '当用户扫描任何巡检单或者工单的第一个巡检点时，系统开始监测，直到所有巡检单和工单完成',
    securityInterval: '当人员连续 {interval1} 分钟无移动，并且在 {interval2} 分钟后机主仍无反应，',
    securityAutoRemindMethod: '自动经以下方式提醒：',

    securityInspmntr: '安全事件（闪烁并播放声音）',
    voiceMsgTips: '语音提醒',
    smsMsgTips: '短信提醒',
    protectionList: '被提醒人',
    protectionListTips: '请选择被提醒人',
    phoneIsEmptyTips: '电话号码为空，请先设置电话号码',
    selectExecutor: '选择人',
    phone: '电话号码',
    createSuccess: '安全卫士已成功创建！',
    editSuccess: '安全卫士已成功修改！',
    securityMonitoringIntervalRangeTips: '长时间不移动时间阈值为：5 ~ 60 分钟',
    securityMonitoringIntervalEmptyTips: '请输入长时间不移动时间阈值',
    securityMonitoringDelayRangeTips: '客户响应时间阈值需小于长时间不移动时间阈值',
    securityMonitoringDelayEmptyTips: '请输入客户响应时间阈值',
  },
  securityRulesMember: {
    groupMember: '组成员',
    search: '请输入登录账号或名称',
    addGroupMenber: '新增',
    deleteSuccess: '成员已成功删除！',
    createSuccess: '成员已成功新增！',
  },
  // 常用理由管理
  commonReason: {
    commonReasonTitle: '常用理由管理',
    commonReasonSearchTip: '请输入理由描述',
    commonReasonDesc: '理由描述',
    commonReasonType: '分类',
    commonReasonAdd: '新增理由项',
    commonReasonEdit: '编辑理由项',
    commonReasonTypeSelectPlh: '请选择分类',
    commonReasonTypeSelectTip: '请选择分类',
    commonReasonSystemTitle: '系统默认理由',
    deleteSuccess: '常用理由删除成功！',
    commonReasonInputDescTip: '请输入理由描述',
    commonReasonDescOver100: '不能超过100字',
    createSuccess: '常用理由已成功创建！',
    editSuccess: '常用理由已成功修改！',
    emptyTips: '未找到相关常用理由。',
    selectTips: '请选择理由',
  },
  // SLA
  sla: {
    slaTitle: 'SLA（服务等级协议）配置',
    slaSearchPlhld: '请输入优先级',
    slaDescLabel: '优先级',
    slaFbtLabel: '反馈时限（分钟）',
    slaDescLongLabel: 'SLA 配置优先级',
    slaAddTitle: '新增 SLA 配置',
    slaEditTitle: '编辑 SLA 配置',
    numberTips: '请输入数值',
    slaEmptyTips: '不能为空',
    slaExceedTips: '不能超过50字',
    slaMaximumTips: '不能大于32000',
    createSuccess: 'SLA 已成功创建！',
    editSuccess: 'SLA 已成功修改！',
    deleteSuccess: 'SLA 删除成功！',
    emptyTips: '未找到相关服务等级协议。',
  },
  // 访客问题管理
  visitorQuestion: {
    visitorQuestionTitle: '访客问题',
    visitorQuestionBankTitle: '题库列表',
    searchTips: '请输入问题',
    visitorQuestionAdd: '新增问题',
    visitorQuestionEdit: '编辑问题',
    visitorQuestionTypeSc: '单选',
    visitorQuestionTypeMc: '多选',
    visitorQuestionTypeIn: '填空',
    visitorQuestionInputtypeTxt: '文本',
    visitorQuestionInputtypeNum: '数字',
    visitorQuestionInputtypeDate: '日期时间',
    visitorQuestionTopicTips: '请输入题目',
    visitorQuestionTopicTypeTips: '请选择题目类型',
    visitorQuestionInputTypeTips: '请选择填空类型',
    visitorQuestionNumTips: '请输入数字',
    visitorQuestionSeqTips: '请输入排序号',
    visitorQuestionOption200Tips: '每一项的内容不能超过200字',
    visitorQuestionOptionalInputTips: '请输入内容',
    visitorQuestionOptionalTips: '请添加可选项',
    visitorQuestionAddToBank: '添加到题库',
    createSuccess: '题目已成功新增！',
    editSuccess: '题目已成功编辑！',
    deleteSuccess: '题目已成功删除！',
    emptyTips: '未找到相关问题。',
  },
  // 访客问题模板
  visitorQuestionTmpl: {
    visitorQuestionTmplTitle: '访客问题模板',
    visitorQuestionTmplSearchTips: '请输入模板名称或描述',
    visitorQuestionTmplName: '模板名称',
    visitorQuestionTmplDesc: '模板描述',
    visitorQuestionTmplAdd: '新增问题模板',
    visitorQuestionTmplEdit: '编辑问题模板',
    visitorQuestion200Tips: '不能超过200字',
    valueFormal: '请输入数值',
    pleaseFillIn: '请填写',
    pleaseSelect: '请选择',
    visitorQuestionInputTypeDateTips: '请选择日期时间',
    visitorQuestionList: '问题列表',
    visitorQuestionAdd: '新增问题',

    visitorQuestionSelect: '从题库中选择',

    visitorQuestionSeq: '排序号',
    visitorQuestionTopic: '题目',
    visitorQuestionTopicType: '题目类型',
    visitorQuestionFillType: '填空类型',
    visitorQuestionRequired: '必填？',
    visitorQuestionTmplNameTips: '请输入问题模板名称',
    visitorQuestionTmplDescTips: '请输入问题模板描述',
    createSuccess: '访客问题模板已成功创建！',
    editSuccess: '访客问题模板已成功修改！',
    deleteSuccess: '访客问题模板删除成功！',
    visitorQuestionOptional: '可选项',
    visitorQuestionAddOptional: '加一项',
    emptyTips: '未找到相关问题模板。',
  },
  // 巡检监控
  inspMn: {
    title: '巡检监控',
    hiddenChartLabel: '隐藏图表',
    todayCheckPntComp: '巡检单完成率（今日）',
    todayCheckPntCompTips1: '在巡检单周期的最后一天完成叫准时完成，在最后一天前完成叫提前完成。',
    todayCheckPntCompTips2: '当天完成的单子(除超时完成之外)数量 / 提前完成或准时完成数量 = 当天完成率。',
    todayCheckPntCompTooltip1: '当天完成的单子(除超时完成之外)数量',
    todayCheckPntCompTooltip2: '提前完成或准时完成数量',
    checkPntChartTitle: '完成',
    weeklyWo: '工单状态（最近1周）',
    weeklyInspPntComp: '巡点完成数TOP10（最近1周）',
    eventRpt: '事件报告',
    eventRpt3Day: '3天内发生的新事件报告',
    inspOrder: '巡检单',
    pendingInspOrder: '待完成的巡检单',
    skipInspPnt: '今日跳过巡检点',
    compInspPnt: '今日完成巡检点',
    wo: '工单',
    pendingWo: '待完成工单',
    executor: '执行人',
    group: '执行组',
    deleteLocation: '该巡检点已删除',
    // 工单图表
    chartComp: '完成',
    chartInProgress: '进行中',
    chartonHold: '暂搁置',
    chartExecuted: '待执行',
    chartCancel: '取消',
    // 巡检状态
    STATUS_COMP: '完成',
    STATUS_ARRIVE: '到达',
    STATUS_NOT_STARTED: '未开始',
    STATUS_EVENT_RPT: '事件报告',
    STATUS_WO_ORDER: '工单',
    STATUS_SKIP: '跳过',
    STATUS_SKIP_REASON: '跳过原因',
    STATUS_ABNORMAL: '异常',
    STATUS_TIMEOUT: '超时',
    STATUS_TIMEOUT_TIP: '长时间未到达',
    STATUS_WAIT_FOR_PROCESS: '待审核',
    STATUS_REFUSE: '退回',

    FIXED: '置顶固定',
    UNPIN: '取消固定',
    VIEW_DETAIL: '查看详细',

    OFFLINE_TIP: '网络连接已断开，请检查您的网络！',
    ONLINE_TIP: '网络连接已恢复！',

    SEARCH_TIPS: '请输入巡检单描述、单号、执行人或执行组',

    INSP_CUSTOM_THEME_LABEL: '自定义主题',
    INSP_CUSTOM_THEME_RESTORE_LABEL: '还原',
    INSP_STYLE_LABEL: '巡检单',
    INSP_STYLE_LABEL_BG_CLR: '背景颜色',
    INSP_STYLE_LABEL_MAIN_TITLE_CLR: '主标题文本',
    INSP_STYLE_LABEL_SUBTITLE_CLR: '子标题文本',
    INSP_STYLE_LABEL_SCROLL_BTN_BG_CLR: '滚动按钮',
    INSP_STYLE_LABEL_SCROLL_BTN_BG_CLR_HOVER: '滚动按钮移入',
    INSP_STYLE_LABEL_SCROLL_BTN_ICON_CLR: '滚动按钮图标',
    INSP_STYLE_LABEL_SCROLL_BTN_ICON_CLR_HOVER: '滚动按钮图标移入',

    INSNUM: '巡检单号',
    INSPLANTIME: '计划巡检时间',
    REVIEWER: '复核人',
    NODATA: '暂无数据',
    LOADING: '加载中...',

    // 安全事件
    mapPoint: '巡点位置',
    SAFETY_INCIDENT_TITLE: '安全事件',
    SAFETY_INCIDENT_RECORDS: '安全事件记录',
    SAFETY_INCIDENT_NEW_RECORDS: '新记录',
    SAFETY_INCIDENT_NO_NEW_RECORDS: '暂无新记录',
    SAFETY_INCIDENT_NO_RECORDS: '暂无记录',

    SAFETY_INCIDENT_VIEW_ALL: '查看所有',
    SAFETY_INCIDENT_COLLAPSE: '收起',
    SAFETY_INCIDENT_START_DATE: '开始日期',
    SAFETY_INCIDENT_END_DATE: '结束日期',
    SAFETY_INCIDENT_TIME: '时间',
    SAFETY_INCIDENT_ACCOUNT: '账号名称',
    SAFETY_INCIDENT_ACCOUNT_PLHD: '请输入账号名称',

    SAFETY_INCIDENT_TYPE: '事件类型',
    SAFETY_INCIDENT_TYPE_ALL: '全部类型',
    SAFETY_INCIDENT_TYPE_LONGNOMOVE: '长时间未移动',

    viewLoc: '查看位置',
    SAFETYVIEWREAD: '已知晓',
  },
  safetyIncident: {
    emptyTips: '未找到相安全事件。',
  },
  // 库存管理
  invMgt: {
    INVTMGTTITLE: '库存管理',
    INVTMATTITLE: '库存物料',
    MATCLEARSEARCH: '清空搜索',
    MATSEARCHTIPS: '搜索物料编号/描述',
    ADDMATERIAL: '添加物料',
    INVTDETAIL: '详情',
    MATERIALNUM: '物料编号',
    MATDESCABBR: '描述',
    MATERIALWH: '仓库',
    MATERIALQTY: '余量',
    JUMPTOPAGE: '跳到',
    JUMPTOPAGEUNIT: '页',
    MATSEARCHRESULT: '搜索记录',
    MATEMPTYTIPS: '没有找到相关的库存物料',
    MATCATEGORY: '物料类别',
    MATSPECIFICATION1: '规格一',
    MATSPECIFICATION2: '规格二',
    MATSPECIFICATION3: '规格三',
    specEnterTips: '请输入规格',
    editSpecSuccessful: '编辑规格成功',
    editSpecFailed: '编辑规格失败',
    MATUNIT: '物料单位',
    deleteUnitSuccessful: '删除物料单位成功',
    deleteUnitFailed: '删除物料单位失败',
    deleteSuccess: '物料已成功删除！',
    MATDESC: '物料描述',
    MATCFMADD: '确认添加',
    MATADDEXIST: '该物料已存在',
    MATSELECTTIPS: '请选择类别',
    MATSPEC1TIPS: '例如品牌',
    MATSPEC2TIPS: '例如型号',
    MATSPEC3TIPS: '例如颜色',
    MATUNITTIPS: '请输入单位',
    matUtilMandatoryTips: '请输入单位',
    utilMandatoryTips: '请输入单位',
    ADDMATEMPTYTIPS: '不能为空',
    MATUNITDELTIPS: '是否删除该单位？',
  },
  // 库存物料
  invMat: {
    MATRETURNTOLIST: '返回列表',
    MATDETCLASS: '类别',
    MATDETUNIT: '单位',
    MATDETEDIT: '编辑',
    MATDETSAVE: '保存',
    MATDETCANCEL: '取消',
    MATDETSPEC: '规格',
    MATDETQTY: '余量',
    MATDETTOTALQTY: '总余量',
    MATDETDESC: '描述',
    MATDETCOST: '平均成本',
    INVTSTATUS: '库存情况',
    INVTSEARCHTIPS: '搜索仓库/货架',
    INVTRPORDERNUM: '入库单/采购单编号',
    INVTPWPRICE: '本次采购/入库的价格',
    INVTMODIFYREASON: '修改原因',
    INVTRPORDERNUM50WORDS: '不能超过50字',
    INVTMODIFYREASON50WORDS: '不能超过200字',

    INVTADD: '添加库存',
    INVTWHNAMETIPS: '请输入仓库名',
    INVTWHNAMETIPSPlh: '请输入仓库名',
    INVTSHELF: '货架',
    INVTSHELFTIPS: '请输入货架号',
    INVTSHELFTIPSPlh: '请输入货架号',
    INVTCOST: '标准成本',
    INVTEMPTYTIPS: '没有找到相关的库存数据',
    INVTADDQTY: '数量',
    INVTADDCFM: '确认添加',
    INVTCONTINUEADD: '确认并继续添加',

    INVTADJUST: '调整库存',
    INVTADJUSTED: '调整后',
    INVTADJUSTBTN: '调整库存',
    INVTADJUSTQTY: '余量调整',
    INVTADJUSTCFM: '确认调整',

    MATTISDELTIPS: '是否删除该物料？',
    INVTISDELTIPS: '是否删除该库存？',
    deleteSuccess: '库存已成功删除！',
    INVTDELTIPS: '库存余量等于 0 时才能删除',
    TOTALINVTDELTIPS: '总余量等于 0 时才能删除',
    WH50WORDS: '仓库不能超过50字',
    SHELF50WORDS: '货架不能超过50字',
    SPECONE50WORDS: '规格一不能超过50字',
    SPECTWO50WORDS: '规格二不能超过50字',
    SPECTHREE50WORDS: '规格三不能超过50字',
    SPEC50WORDS: '规格不能超过50字',
    SPECEMPTYTIPS: '规格不能为空',
    MATUNIT50WORDS: '单位不能超过50字',
    DELWHTIPS: '是否删除该仓库？',
    DELSHELFTIPS: '是否删除该货架？',
    DELWHTIPSSUCCESS: '仓库已成功删除！',
    DELSHELFTIPSSUCCESS: '货架已成功删除！',
    WHNOEMPTYTIPS: `该仓库有 {str} 等物料，不能删除`,
    SHELFNOEMPTYTIPS: `该货架有 {str} 等物料，不能删除`,

    MATDETISSUANCE: '发料',
    MATDETRETURN: '退料',
    VIEWMATDET: '查看物料',
  },
  // 物料使用记录
  matUsageRecord: {
    title: '物料使用记录',
    ISSUETYPE_ALL: '全部',
    ISSUETYPE_ISSUE: '发放库存项目',
    ISSUETYPE_RETURN: '返回库存项目',
    ISSUETYPE_AVGCSTADJ: '平均成本调整',
    ISSUETYPE_CURBALADJ: '当前余量调整',
    ISSUETYPE_INSERTITEM: '插入新库存项目',
    ISSUETYPE_RECEIPT: '接收库存项目',
    MATSELECTTIPS: '请选择',

    MATUSEDATE: '时间',
    MATWONUM: '工单编号',
    MATDETAILWONUM: '工单编号',
    matDetailWoDesc: '工单描述',
    MATUSESTATUS: '使用情况',
    MATUSEQUANTITY: '数量',

    MATUSAGERECORDDETAIL: '物料使用记录明细',
    MATUSEDESC: '使用描述',
    MATUSETYPE: '使用类型',
    MATUSECREATEDATE: '使用时间',
    MATUSER: '使用人员',
    MATPATPNAME: '巡检点名称',
    MATPATPNUM: '巡检点编号',
    MATWHNUM: '仓库编号',
    MATSHELFNAME: '货架',
    MATSHELFNUM: '货架编号',

    MAT_ISSUE: '发放库存项目',
    MAT_BEFORE_ISSUE: '调整前余量',
    MAT_AFTER_ISSUE: '调整后余量',

    MAT_RETURN: '返回库存项目',
    MAT_BEFORE_RETURN: '调整前余量',
    MAT_AFTER_RETURN: '调整后余量',

    MAT_AVGCSTADJ: '平均成本调整',
    MAT_BEFORE_AVGCSTADJ: '调整前余量',
    MAT_AFTER_AVGCSTADJ: '调整后余量',

    MAT_CURBALADJ: '当前余量调整',
    MAT_BEFORE_CURBALADJ: '调整前余量',
    MAT_AFTER_CURBALADJ: '调整后余量',

    MAT_INSERTITEM: '插入新库存项目',
    MAT_BEFORE_INSERTITEM: '调整前余量',
    MAT_AFTER_INSERTITEM: '调整后余量',

    MAT_RECEIPT: '接收库存项目',
    MAT_BEFORE_RECEIPT: '调整前余量',
    MAT_AFTER_RECEIPT: '调整后余量',

    MATCOST: '成本变动',
    MATNEWCOST: '新平均成本',
    MATOLDCOST: '旧平均成本',
    emptyTips: '未找到相关物料使用记录。',
  },
  rentPropertyList: {
    title: '物件清单',
    enterSearch: '请输入物件名称',
    name: '物件名称',
    nameTips: '请输入物件名称',
    nameExceedTips: '物件名称不能超过50字',
    labelType: '物件标识类型',
    labelTypePlh: '请选择物件标识类型',
    labelTypeTips: '请选择物件标识类型',
    qrcode: '二维码',
    lastDate: '最后交易日期',
    status: '当前状态',
    statusPlh: '请选择状态',
    lent: '已借出',
    available: '可用',
    changeToAvailable: '改为可用',
    lost: '遗失',
    stop: '停用',
    stopped: '停用',
    photo: '照片',
    createTitle: '创建物件',
    editTitle: '编辑物件',
    detailTitle: '物件详细',
    confirmDelete: '请确认是否删除该物件？',
    createSuccess: '物件已成功创建！',
    editSuccess: '物件已成功编辑！',
    deleteSuccess: '物件已成功删除！',
    stopSuccess: '物件已成功停用！',
    enableSuccess: '物件已成功改为可用！',
    emptyTips: '未找到相关物件。',
  },
  selectRentalProperty: {
    title: '物件列表',
    selected: '已选择 {count}',
    selectedProperty: '已选择的物件',
    selectTips: '请选择物件',
  },
  lendReturn: {
    title: '借用 / 归还',
    enterSearch: '请输入物件名称',
    num: '借用单编号',
    name: '物件名称',
    nameTips: '请选择物件',
    labelType: '物件标识类型',
    lendPurpose: '借用理由',
    lendPurposePlh: '请输入或选择借用理由',
    lendPurposeTips: '请输入或选择借用理由',
    lendReason: '借用理由',
    setCommonPurpose: '设为常用理由',
    propertyPhoto: '物件照片',
    transactionDate: '借用日期',
    transactionDateTips: '请选择借用日期',
    selectDate: '选择日期',
    expectedReturnDate: '预计归还日期',
    expectedReturnDateTips: '请选择预计归还日期',
    editReturnDateReason: '修改理由',
    editReasonPh: '请输入修改预计归还日期理由',
    editReasonTips: '请输入修改预计归还日期理由',
    recipient: '借用人姓名',
    recipientTips: '请输入或选择借用人',
    recipientPlh: '请输入或选择借用人',
    selectRecipientTitle: '借用人列表',
    recipientId: '借用人证件号码',
    recipientIdTips: '请输入借用人证件号码',
    recipientPhone: '借用人手机号码',
    recipientPhoneTips: '请输入借用人手机号码',
    handler: '经手人',
    handlerTips: '请输入经手人',
    handlerId: '经手人证件号码',
    handlerIdTips: '请输入经手人证件号码',
    handlerIdRuleTips: '只能输入数字、英文或特殊符号，特殊符号仅支持#-',
    returnStatus: '归还状态',
    toBeReturned: '已借出',
    returned: '已归还',
    return: '归还',
    confirmReturnTips: '确认归还？',
    returnSuccess: '物件已成功归还！',
    createTitle: '创建借用单',
    editTitle: '编辑借用单',
    detailTitle: '借用单详细',
    continueAdd: '继续新增',
    createSuccess: '借用单已成功创建！',
    editSuccess: '借用单已成功编辑！',
    emptyTips: '未找到相关借用单。',
    maxLentTime: '最多可借用时长',
    day: '天',
    hour: '小时',
    maxLentTimeUnitTips: '请选择时间单位',
    maxLentTimeUnitPlh: '请选择时间单位',
  },
  disposalOrder: {
    title: '报废处置单',
    reason: '处置原因/理由',
    reasonPlh: '请输入或选择处置原因/理由',
    reasonTips: '请输入或选择处置原因/理由',
    disposalReason: '处置理由',
    rentalOrder: '借用单',
    confirmer: '确认人',
    confirmerTips: '请选择确认人',
    selectConfirmerTitle: '确认人列表',
    attachment: '附件',
    disposalPhoto: '报废处置单照片',
    status: '状态',
    confirmed: '已确认',
    unconfirmed: '未确认',
    cancelled: '已取消',
    createTitle: '创建报废处置单',
    editTitle: '编辑报废处置单',
    detailTitle: '报废处置单详细',
    cancelDisposalOrder: '取消报废处置单',
    cancelDisposalOrderTips: '请确认是否取消报废处置单？',
    cancelDisposalOrderSuccess: '报废处置单已成功取消！',
    confirmDisposalOrder: '确认报废处置单',
    confirmDisposalOrderTips: '请确认是否确认报废处置单？',
    confirmDisposalOrderSuccess: '报废处置单已成功确认！',
    confirmTime: '确认时间',
    cancelTime: '取消时间',
    createSuccess: '报废处置单已成功创建！',
    editSuccess: '报废处置单已成功编辑！',
    emptyTips: '未找到相关报废处置单。',
    attachmentUploadFaild: '附件上传失败！',
    isExistLendOrder: '该物件创建了借用单，单号为：{num}',
    attachmentTypeTips: '只能上传 .jpg、.jpeg、.png、.pdf 格式的文件',
  },
  patrolTrack: {
    inspectionPointList: '巡检点列表',
    track: '追踪',
  },
  guide: {
    previous: '上一步',
    next: '下一步',
    goOpen: '去打开',
    goCreate: '去创建',
    goPreview: '去预览',
    goView: '去查看',
    goEnable: '去启用',
    goSet: '去设置',
    goAdd: '去添加',
    finish: '完成',
    ok: '确定',
    understand: '了解',
    continueCreate: '继续创建',
    nextStage: '进入下一阶段',
    noLocTips: '检测到您还没有创建有效的巡检点，请先前往创建再执行该操作。',
    noAccountTips: '检测到您还没有创建使用账号，请先前往创建再执行该操作。',
    notAllowPopupTips: '由于您的浏览器不允许弹出式窗口，导致该操作无法完成。请在浏览器设置中开启弹出式窗口后再尝试执行该操作。',
  },
  tutorialVideo: {
    welcome: '观看教程视频',
    subtitle: '带您完整探索313云巡检平台',
    basic: '基础课程',
    basic_video1: '课程1 : 创建物业实体',
    basic_video2: '课程2 : 创建使用账号',
    basic_video3: '课程3 : 使用账号的登录',
    basic_video4: '课程4 : 巡检点创建及维护',
    basic_video5: '课程5 : 创建巡检计划',

    other: '其他功能',
    other_video1: '功能1 : 手机巡检操作',
    other_video2: '功能2 : 巡检单事件报告和数据采集',

    or: '或者',

    viewBasicCourse: '查看基础课程',
    viewTutorial: '查看教程',
  },
  classManagement: {
    title: '分类管理',
    searchTips: '请输入分类名称或描述',
    applyNameSelectTips: '请选择应用名称',
    copyFromSystem: '从系统复制',
    defineName: '分类名称',
    applyName: '应用名称',
    description: '描述',
    active: '有效？',
    activeTips: '同一应用名称只允许一个生效。',
    confirmDelete: '请确认是否删除该记录？',
    deleteSuccess: '分类已成功删除！',
    emptyTips: '未找到相关分类。',
    copyFromSysSearchPlh: '请输入分类名称或应用名称',
  },
  classManagementCreate: {
    title: '创建分类',
    editTitle: '编辑分类',
    valueTree: '分类数据树',
    addClass: '添加分类',
    moveClass: '移动分类',
    copyClass: '复制分类',
    deleteClass: '删除分类',
    deleteClassConfirm: '请确认是否删除分类？',
    classValueInfo: '分类数据信息',
    classValue: '分类数据',
    classValueTips: '请输入分类数据',
    classDescTips: '请输入分类描述',
    classValueExistTips: '（{classValue}）的记录已存在。',
    labelRepeatTips: '选中项中存在重复项（{label}）。',
    cannotEnterSlashTips: '不能输入 "/" 符号',
    lastLevelAddTips: '已到该分类结构底层，无法继续添加。',
    lastLevelCopyTips: '已到该分类结构底层，无法复制到该分类里。',
    lastLevelMoveTips: '已到该分类结构底层，无法移动到该分类里。',
    cannotSelectSelfOrChild: '不能选择自己或子级。',
    copyClassTo: '复制分类到',
    moveClassTo: '移动分类到',
    searchTips: '请输入描述',
    defineNameEmptyTips: '请输入分类名称',
    onlyNumberOrLetter: '只能输入数字或字母',
    applyNameSelectTips: '请选择应用名称',
    charLimitTips: '不能超过{num}字',
    createSuccess: '分类已成功创建！',
    editSuccess: '分类已成功编辑！',
    emptyTips: '请添加分类！',
  },
  modifyRecords: {
    emptyTips: '未找到相关编辑记录。',
  }
}

export default lang