<template>
  <div class="container">
    <div class="header">
      <el-date-picker
        v-model="datetime"
        type="datetimerange"
        :picker-options="chartPickerOptions"
        @change="changeChartDate"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        :range-separator="$t('common.to')"
        :start-placeholder="$t('home.startDate')"
        :end-placeholder="$t('home.endDate')"
      >
      </el-date-picker>
    </div>
    <div class="chart-wrapper" ref="chartWrapper"></div>
  </div>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
